import { Record } from 'immutable';
import { ActionTypes } from './types';
import {
  IThimbleFlowState,
  ISetActivityID,
  ISetPaymentOption,
  ISetPurchasedPolicyDetails,
  ISetSelectedQuote,
  ISetCart,
  ISetUserAnswers,
} from './interface';
import { getStore } from '../../utils/persist';

type Actions =
  | ISetUserAnswers
  | ISetCart
  | ISetActivityID
  | ISetSelectedQuote
  | ISetPaymentOption
  | ISetPurchasedPolicyDetails;

const persistedStore = getStore();

const record = Record<IThimbleFlowState>({
  selectedQuote: persistedStore?.thimbleFlow?.selectedQuote || null,
  paymentOption: persistedStore?.thimbleFlow?.paymentOption || null,
  purchasedPolicyDetails: persistedStore?.thimbleFlow?.purchasedPolicyDetails || null,
  activityID: persistedStore?.thimbleFlow?.activityID || null,
  cart: persistedStore?.thimbleFlow?.cart || null,
  userAnswers: persistedStore?.thimbleFlow?.userAnswers || {},
});

class ThimbleFlowState extends record implements IThimbleFlowState {}

export default function reducer(state = new ThimbleFlowState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetSelectedQuote: {
      return state.set('selectedQuote', action.payload.selectedQuote);
    }

    case ActionTypes.SetPurchasedPolicyDetails: {
      return state.set('purchasedPolicyDetails', action.payload.purchasedPolicyDetails);
    }

    case ActionTypes.SetPaymentOption: {
      return state.set('paymentOption', action.payload.paymentOption);
    }

    case ActionTypes.SetActivityID: {
      return state.set('activityID', action.payload.activityID);
    }

    case ActionTypes.SetCart: {
      return state.set('cart', action.payload.cart);
    }

    case ActionTypes.SetUserAnswers: {
      return state.set('userAnswers', { ...(state?.userAnswers || {}), ...action.payload.userAnswers });
    }

    default: {
      return state;
    }
  }
}
