import { Middleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';

const persist: Middleware = store => next => action => {
  setTimeout(() => {}, 1000);

  return next(action);
};

const middlewares = [];

middlewares.push(thunk);

// const store = createStore(reducer, applyMiddleware(...middlewares));

const store = configureStore({ reducer, middleware: middlewares });

store.subscribe(() => {
  sessionStorage.setItem('store', JSON.stringify(store.getState().toJS()));
});

export default store;
