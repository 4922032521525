import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import delay from 'delay';
import { IntercomProvider, useIntercom as useReactIntercom } from 'react-use-intercom';
import { setLoading, setVisible } from '../redux/intercom';

export const getConversationID = (): string => {
  const intercomState = localStorage.getItem('intercom.intercom-state-ggekus3b');
  if (!intercomState) return '';
  const conversationIds = Object.keys(JSON.parse(intercomState).conversations.byId);
  return conversationIds.length ? conversationIds[conversationIds.length - 1] : '';
};

export function Intercom({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();

  return (
    <IntercomProvider
      onShow={() => {
        dispatch(setLoading(false));
        dispatch(setVisible(true));
      }}
      onHide={() => {
        dispatch(setVisible(false));
      }}
      appId={'ggekus3b'}
      autoBoot={true}
      autoBootProps={{
        customLauncherSelector: '#intercom-chat',
        hideDefaultLauncher: true,
      }}
      initializeDelay={5000}
    >
      {children}
    </IntercomProvider>
  );
}

export function useIntercom() {
  const { update, show, trackEvent } = useReactIntercom();
  const dispatch = useDispatch();

  return {
    update,
    show,
    trackEvent,
    run: async () => {
      dispatch(setLoading(true));
      update();
      await delay(3000);
      show();
      await delay(500);
    },
  };
}
