import { Record } from 'immutable';
import { Root } from 'react-dom/client';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_DISPLAY_FORMAT = 'MMM DD';

export enum ActionTypes {
  SetLoading = 'Thimble/SetLoading',
  SetAdditionalCoverage = 'Thimble/SetAdditionalCoverage',
  SetAdditionalEmployees = 'Thimble/SetAdditionalEmployees',
  SetEmployeesAndPayrollValue = 'Thimble/SetEmployeesAndPayrollValue',
  SetStartAndEndDate = 'Thimble/SetStartAndEndDate',
  SetBusinessActivities = 'Thimble/SetBusinessActivities',
  SetActivityID = 'Thimble/SetActivityID',
  SetAllActivities = 'Thimble/SetAllActivities',
  SetActivitiesLimits = 'Thimble/SetActivitiesLimits',
  SetCoverageOptions = 'Thimble/SetCoverageOptions',
  SetSelectedPlan = 'Thimble/SetSelectedPlan',
  SetCart = 'Thimble/SetCart',
  SetPolicyDetails = 'Thimble/SetPolicyDetails',
  SetRateID = 'Thimble/SetRateID',
  SetPaymentFrequency = 'Thimble/SetPaymentFrequency',
  SetAddons = 'Thimble/SetAddons',
}

export enum Activities {
  Appliance_Installation = 'Appliance Installation',
  Chimney_Cleaning = 'Chimney Cleaning',
  Concrete_Construction = 'Concrete Construction',
  Exterior_Painting = 'Exterior Painting',
  Floor_Waxing = 'Floor Waxing',
  HVAC = 'HVAC',
  Plastering = 'Plastering',
  Plumbing = 'Plumbing',
  Snow_IceRemoval = 'Snow / Ice Removal',
  Sign_Installation = 'Sign Installation',
  Roofing = 'Roofing',
}

export enum CodeValues {
  Appliance_Installation = '2001001',
  Chimney_Cleaning = '2001002',
  Concrete_Construction = '2001003',
  Exterior_Painting = '2001004',
  Floor_Waxing = '2001005',
  HVAC = '2001006',
  Plastering = '2001009',
  Plumbing = '2001010',
  Snow_IceRemoval = '2001011',
  Sign_Installation = '2001013',
  Roofing = '2001021',
}

export interface IBusinessActivities {
  activities: string[];
}

export interface IStartAndEndDate {
  startDate: string;
  endDate: string;
}

export interface IAdditionalEmployees {
  additionalEmployees: null | number;
}

export interface IAdditionalCoverage {
  includeAdditionalCoverage: undefined | boolean;
}
export interface IAllActivities {
  activities: [];
}

export enum PaymentFrequency {
  Daily = 'daily_rate_by_dollar',
  Monthly = 'monthly_rate_by_dollar',
}

export interface IRate {
  limit: number | string;
  quote_id: string;
  [PaymentFrequency.Daily]: number | string;
  [PaymentFrequency.Monthly]: number | string;
  rate_by_dollar: number | string;
}

export interface IAddon {
  description: string;
  insurance_type: string;
  plan_id: string;
  plan_name: string;
}

export interface ICoverageOptions {
  [key: string]: any;
  plan_name: string;
  insurance_type: string;
  description: string;
  addons: IAddon[];
  planRates: IRate[];
}

export interface ISelectedPlan {
  planId: string;
  planType: string;
  planLimit: string | number;
  addons: string[];
  quote_id: string;
}

export interface ICart {
  [x: string]: any;
  plan: {
    [key: string]: any[];
  };
}

export interface IPolicyDetails {
  policyNumber: string;
  totalAmount: number | string;
  startDate: string;
  endDate: string;
}

export interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

export interface ISetRateID {
  type: ActionTypes.SetRateID;
  payload: {
    rateID: string;
  };
}

export interface ISetAdditionalEmployees {
  type: ActionTypes.SetAdditionalEmployees;
  payload: {
    additionalEmployees: number;
  };
}

export interface ISetAdditionalCoverage {
  type: ActionTypes.SetAdditionalCoverage;
  payload: {
    includeAdditionalCoverage: undefined | boolean;
  };
}

export interface ISetAddons {
  type: ActionTypes.SetAddons;
  payload: {
    addons: string[];
  };
}

export interface ISetStartAndEndDate {
  type: ActionTypes.SetStartAndEndDate;
  payload: {
    name: 'startDate' | 'endDate';
    value: string;
  };
}

export interface ISetBusinessActivities {
  type: ActionTypes.SetBusinessActivities;
  payload: {
    businessActivities: IBusinessActivities;
  };
}

export interface ISetAllActivities {
  type: ActionTypes.SetAllActivities;
  payload: {
    activities: [];
  };
}
export interface ISetActivitiesLimits {
  type: ActionTypes.SetActivitiesLimits;
  payload: {
    limits: [];
  };
}

export interface ISetCoverageOptions {
  type: ActionTypes.SetCoverageOptions;
  payload: {
    coverageOptions: ICoverageOptions;
  };
}

export interface ISetSelectedPlan {
  type: ActionTypes.SetSelectedPlan;
  payload: {
    selectedPlan: ISelectedPlan;
  };
}

export interface ISetCart {
  type: ActionTypes.SetCart;
  payload: {
    cart: ICart;
  };
}

export interface ISetPolicyDetails {
  type: ActionTypes.SetPolicyDetails;
  payload: {
    policyDetails: IPolicyDetails;
  };
}

export interface ISetActivityID {
  type: ActionTypes.SetActivityID;
  payload: {
    activityID: string;
  };
}

export interface ISetPaymentFrequency {
  type: ActionTypes.SetPaymentFrequency;
  payload: {
    paymentFrequency: PaymentFrequency;
  };
}

export interface IThimbleState {
  loading: boolean;
  activityID: string;
  rateID: string;
  paymentFrequency: PaymentFrequency;
  addons: string[];
  coverageOptions: ICoverageOptions;
  includeAdditionalCoverage: undefined | boolean;
  additionalEmployees: null | number;
  startAndEndDate: {
    startDate: string;
    endDate: string;
  };
  businessActivities: IBusinessActivities;
  activitiesList: [];
  limits: [];
  selectedPlan: ISelectedPlan;
  cart: {};
  policyDetails: IPolicyDetails;
}

type Actions =
  | ISetLoading
  | ISetStartAndEndDate
  | ISetBusinessActivities
  | ISetAdditionalEmployees
  | ISetAdditionalCoverage
  | ISetAllActivities
  | ISetActivitiesLimits
  | ISetCoverageOptions
  | ISetSelectedPlan
  | ISetCart
  | ISetPolicyDetails
  | ISetActivityID
  | ISetRateID
  | ISetPaymentFrequency
  | ISetAddons;

const persistedStore = getStore();

const record = Record<IThimbleState>(
  persistedStore?.thimble
    ? persistedStore.thimble
    : {
        loading: false,
        includeAdditionalCoverage: undefined as undefined | boolean,
        activityID: '',
        additionalEmployees: null as null | number,
        startAndEndDate: {
          startDate: '',
          endDate: '',
        },
        businessActivities: {
          activities: [],
        },
        activitiesList: [],
        limits: [],
        paymentFrequency: PaymentFrequency.Daily,
        rateID: '',
        addons: [],
        coverageOptions: {
          plan_name: '',
          insurance_type: '',
          description: '',
          addons: [],
          planRates: [],
        },
        selectedPlan: {
          planId: '',
          addons: [],
          planType: PaymentFrequency.Daily,
          planLimit: '1000000',
          quote_id: '',
        },
        cart: {},
        policyDetails: {
          policyNumber: '',
          totalAmount: '',
          startDate: '',
          endDate: '',
        },
      }
);

class ThimbleState extends record implements IThimbleState {}

// reducer
export default function reducer(state = new ThimbleState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    case ActionTypes.SetAdditionalEmployees: {
      return state.set('additionalEmployees', action.payload.additionalEmployees);
    }

    case ActionTypes.SetStartAndEndDate: {
      return state.setIn(['startAndEndDate', action.payload.name], action.payload.value);
    }

    case ActionTypes.SetBusinessActivities: {
      return state.set('businessActivities', action.payload.businessActivities);
    }

    case ActionTypes.SetAdditionalCoverage: {
      return state.set('includeAdditionalCoverage', action.payload.includeAdditionalCoverage);
    }

    case ActionTypes.SetAllActivities: {
      return state.set('activitiesList', action.payload.activities);
    }

    case ActionTypes.SetActivitiesLimits: {
      return state.set('limits', action.payload.limits);
    }

    case ActionTypes.SetCoverageOptions: {
      return state.set('coverageOptions', action.payload.coverageOptions);
    }

    case ActionTypes.SetSelectedPlan: {
      return state.set('selectedPlan', action.payload.selectedPlan);
    }

    case ActionTypes.SetCart: {
      return state.set('cart', action.payload.cart);
    }

    case ActionTypes.SetPolicyDetails: {
      return state.set('policyDetails', action.payload.policyDetails);
    }

    case ActionTypes.SetActivityID: {
      return state.set('activityID', action.payload.activityID);
    }

    case ActionTypes.SetRateID: {
      return state.set('rateID', action.payload.rateID);
    }

    case ActionTypes.SetPaymentFrequency: {
      return state.set('paymentFrequency', action.payload.paymentFrequency);
    }

    case ActionTypes.SetPaymentFrequency: {
      return state.set('paymentFrequency', action.payload.paymentFrequency);
    }

    case ActionTypes.SetAddons: {
      return state.set('addons', action.payload.addons);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setAdditionalEmployees = (additionalEmployees: null | number) => ({
  type: ActionTypes.SetAdditionalEmployees,
  payload: {
    additionalEmployees,
  },
});

export const setIncludeAdditionalCoverage = (includeAdditionalCoverage: undefined | boolean) => ({
  type: ActionTypes.SetAdditionalCoverage,
  payload: {
    includeAdditionalCoverage,
  },
});

export const setStartAndEndDate = (name: 'startDate' | 'endDate', value: string): ISetStartAndEndDate => ({
  type: ActionTypes.SetStartAndEndDate,
  payload: {
    name,
    value,
  },
});

export const setBusinessActivities = (businessActivities: IBusinessActivities): ISetBusinessActivities => ({
  type: ActionTypes.SetBusinessActivities,
  payload: {
    businessActivities,
  },
});

export const setAllActivities = (activities: []): ISetAllActivities => ({
  type: ActionTypes.SetAllActivities,
  payload: {
    activities,
  },
});

export const setActivitiesLimit = (limits: []): ISetActivitiesLimits => ({
  type: ActionTypes.SetActivitiesLimits,
  payload: {
    limits,
  },
});

export const setCoverageOptions = (coverageOptions: ICoverageOptions): ISetCoverageOptions => ({
  type: ActionTypes.SetCoverageOptions,
  payload: {
    coverageOptions,
  },
});

export const setSelectedPlan = (selectedPlan: ISelectedPlan): ISetSelectedPlan => ({
  type: ActionTypes.SetSelectedPlan,
  payload: {
    selectedPlan,
  },
});

export const setPlanToCard = (cart: ICart): ISetCart => ({
  type: ActionTypes.SetCart,
  payload: {
    cart,
  },
});

export const setPolicyDetails = (policyDetails: IPolicyDetails): ISetPolicyDetails => ({
  type: ActionTypes.SetPolicyDetails,
  payload: {
    policyDetails,
  },
});

export const setActivityID = (activityID: string): ISetActivityID => ({
  type: ActionTypes.SetActivityID,
  payload: {
    activityID,
  },
});

export const setRateID = (rateID: string): ISetRateID => ({
  type: ActionTypes.SetRateID,
  payload: {
    rateID,
  },
});

export const setPaymentFrequency = (paymentFrequency: PaymentFrequency): ISetPaymentFrequency => ({
  type: ActionTypes.SetPaymentFrequency,
  payload: {
    paymentFrequency,
  },
});

export const setAddons = (addons: string[]): ISetAddons => ({
  type: ActionTypes.SetAddons,
  payload: {
    addons,
  },
});

// selectors
export const getThimble = (rootState: RootState): IThimbleState => rootState.get('thimble').toJS();

export const getCoverageOptions = (rootState: RootState): ICoverageOptions =>
  rootState.getIn(['thimble', 'coverageOptions']) as ICoverageOptions;

export const getLoading = (rootState: RootState): boolean => rootState.getIn(['thimble', 'loading']) as boolean;

export const getAdditionalEmployees = (rootState: RootState): number =>
  rootState.getIn(['thimble', 'additionalEmployees']) as number;

export const getAdditionalCoverage = (rootState: RootState): IAdditionalCoverage =>
  rootState.getIn(['thimble', 'includeAdditionalCoverage']) as IAdditionalCoverage;

export const getStartAndEndDate = (rootState: RootState): IStartAndEndDate =>
  rootState.getIn(['thimble', 'startAndEndDate']) as IStartAndEndDate;

export const getBusinessActivities = (rootState: RootState): IBusinessActivities =>
  rootState.getIn(['thimble', 'businessActivities']) as IBusinessActivities;

export const getActivitiesList = (rootState: RootState): IAllActivities =>
  rootState.getIn(['thimble', 'activitiesList']) as IAllActivities;

export const getActivitiesLimits = (rootState: RootState): [] => rootState.getIn(['thimble', 'limits']) as [];

export const getCart = (rootState: RootState): ICart => rootState.getIn(['thimble', 'cart']) as ICart;

export const getPolicyDetails = (rootState: RootState): IPolicyDetails =>
  rootState.getIn(['thimble', 'policyDetails']) as IPolicyDetails;

export const getSelectedPlan = (rootState: RootState): ISelectedPlan =>
  rootState.getIn(['thimble', 'selectedPlan']) as ISelectedPlan;

export const getActivityID = (rootState: RootState): string => rootState.getIn(['thimble', 'activityID']) as string;

export const getSelectedRate = (rootState: RootState): IRate | undefined => {
  const { rateID, coverageOptions } = getThimble(rootState);
  return coverageOptions.planRates.find(x => x.quote_id === rateID);
};

export const getSelectedAddons = (rootState: RootState): IAddon[] => {
  const { addons, coverageOptions } = getThimble(rootState);
  return coverageOptions.addons.filter(x => addons.includes(x.plan_id));
};
