import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
export type Fork = 'bp' | 'kayak-policy' | 'thimble' | 'kayak-quote';

export interface IGlobalState {
  loading: boolean;
  error: string;
  fork: Fork;
  desktopSetpper: number;
  fastPassResp: any;
  carriersToShowQuestions: any[];
  unlockedCarriers: string[];
  questionsSequence: string[];
  cIAppetiteResp: any;
}

enum ActionTypes {
  SetLoading = 'Global/SetLoading',
  SetError = 'Global/SetError',
  SetFork = 'Global/SetFork',
  SetdDesktopSetpper = 'Global/SetdDesktopSetpper',
  SetFastPassResp = 'Global/SetFastPassResp',
  SetCarriersToShowQuestions = 'Global/SetCarriersToShowQuestions',
  SetUnlockedCarriers = 'Global/SetUnlockedCarriers',
  SetQuestionsSequence = 'Global/SetQuestionsSequence',
  SetCIAppetiteResp = 'Global/SetCIAppetiteResp',
}

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

interface ISetError {
  type: ActionTypes.SetError;
  payload: {
    message: string;
  };
}

interface ISetFork {
  type: ActionTypes.SetFork;
  payload: {
    fork: Fork;
  };
}

interface ISetdDesktopSetpper {
  type: ActionTypes.SetdDesktopSetpper;
  payload: {
    step: number;
  };
}

interface ISetFastPassResp {
  type: ActionTypes.SetFastPassResp;
  payload: {
    fastPassResp: any;
  };
}

interface IsetCIAppetiteResp {
  type: ActionTypes.SetCIAppetiteResp;
  payload: {
    cIAppetiteResp: any;
  };
}

interface ISetCarriersToShowQuestions {
  type: ActionTypes.SetCarriersToShowQuestions;
  payload: {
    carriersToShowQuestions: any[];
  };
}

interface ISetUnlockedCarriers {
  type: ActionTypes.SetUnlockedCarriers;
  payload: {
    unlockedCarriers: string[];
  };
}
interface ISetQuestionsSequence {
  type: ActionTypes.SetQuestionsSequence;
  payload: {
    questionsSequence: string[];
  };
}

type Actions =
  | ISetLoading
  | ISetError
  | ISetFork
  | ISetdDesktopSetpper
  | ISetFastPassResp
  | ISetCarriersToShowQuestions
  | ISetUnlockedCarriers
  | ISetQuestionsSequence
  | IsetCIAppetiteResp;

const persistedStore = getStore();

// state
const record = Record<IGlobalState>(
  persistedStore?.global
    ? persistedStore?.global
    : {
        loading: false,
        error: '',
        fork: '' as Fork,
        desktopSetpper: 0,
        fastPassResp: [],
        carriersToShowQuestions: [],
        unlockedCarriers: [],
        questionsSequence: [],
        cIAppetiteResp: {},
      }
);

export class GlobalState extends record implements IGlobalState {
  setLoading(loading: boolean) {
    return this.set('loading', loading);
  }
}

// reducer
export default function reducer(state = new GlobalState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetLoading: {
      return state.setLoading(action.payload.loading);
    }

    case ActionTypes.SetError: {
      return state.set('error', action.payload.message);
    }

    case ActionTypes.SetFork: {
      return state.set('fork', action.payload.fork);
    }

    case ActionTypes.SetdDesktopSetpper: {
      return state.set('desktopSetpper', action.payload.step);
    }

    case ActionTypes.SetFastPassResp: {
      return state.set('fastPassResp', action.payload.fastPassResp);
    }

    case ActionTypes.SetCIAppetiteResp: {
      return state.set('cIAppetiteResp', action.payload.cIAppetiteResp);
    }

    case ActionTypes.SetCarriersToShowQuestions: {
      return state.set('carriersToShowQuestions', action.payload.carriersToShowQuestions);
    }

    case ActionTypes.SetUnlockedCarriers: {
      return state.set('unlockedCarriers', action.payload.unlockedCarriers);
    }

    case ActionTypes.SetQuestionsSequence: {
      return state.set('questionsSequence', action.payload.questionsSequence);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setFork = (fork: Fork): ISetFork => ({
  type: ActionTypes.SetFork,
  payload: {
    fork,
  },
});

export const setdDesktopSetpper = (step: number): ISetdDesktopSetpper => ({
  type: ActionTypes.SetdDesktopSetpper,
  payload: {
    step,
  },
});

export const setError = (message: string): ISetError => ({
  type: ActionTypes.SetError,
  payload: {
    message,
  },
});

export const setFastPassResp = (fastPassResp: any): ISetFastPassResp => ({
  type: ActionTypes.SetFastPassResp,
  payload: {
    fastPassResp,
  },
});

export const setCIAppetiteResp = (cIAppetiteResp: any): IsetCIAppetiteResp => ({
  type: ActionTypes.SetCIAppetiteResp,
  payload: {
    cIAppetiteResp,
  },
});

export const setCarriersToShowQuestions = (carriersToShowQuestions: any[]): ISetCarriersToShowQuestions => ({
  type: ActionTypes.SetCarriersToShowQuestions,
  payload: {
    carriersToShowQuestions,
  },
});

export const setUnlockedCarriers = (unlockedCarriers: string[]): ISetUnlockedCarriers => ({
  type: ActionTypes.SetUnlockedCarriers,
  payload: {
    unlockedCarriers,
  },
});

export const setQuestionsSequence = (questionsSequence: string[]): ISetQuestionsSequence => ({
  type: ActionTypes.SetQuestionsSequence,
  payload: {
    questionsSequence,
  },
});

// selectors
export const getLoading = (rooState: RootState): boolean => rooState.getIn(['global', 'loading']) as boolean;

export const getFork = (rooState: RootState): string => rooState.getIn(['global', 'fork']) as string;

export const getDesktopSetpper = (rooState: RootState): number =>
  rooState.getIn(['global', 'desktopSetpper']) as number;

export const getError = (rooState: RootState): string => rooState.getIn(['global', 'error']) as string;

export const getFastPassResp = (rooState: RootState): any => rooState.getIn(['global', 'fastPassResp']);
export const getCIAppetiteResp = (rooState: RootState): any => rooState.getIn(['global', 'cIAppetiteResp']);

export const getCarriersToShowQuestions = (rooState: RootState): any =>
  rooState.getIn(['global', 'carriersToShowQuestions']);

export const getUnlockedCarriers = (rooState: RootState): any => rooState.getIn(['global', 'unlockedCarriers']);

export const getQuestionsSequence = (rooState: RootState): string[] =>
  rooState.getIn(['global', 'questionsSequence']) as string[];
