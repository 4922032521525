import { Record } from 'immutable';
import { RootState } from './types';
import { BusinessStructure, Coverage, CoverageLength, Job } from '../types';
import { IPersonalDetails } from './user';
import { getStore } from '../utils/persist';

// types
enum ActionTypes {
  SetLoading = 'Prefill/SetLoading',
  SetBusinessInfo = 'Prefill/SetBusinessInfo',
  SetModalAppear = 'Prefill/SetModalAppear',
  SetFoundingYear = 'Prefill/SetFoundingYear',
  //SetFullTimeEmployees = 'Prefill/SetFullTimeEmployees',
  SetBusinessStructure = 'Prefill/SetBusinessStructure',
  SetProfession = 'Prefill/SetProfession',
  SetNoPrefillData = 'Prefill/SetNoPrefillData',
  SetInsuranceStatus = 'Prefill/SetInsuranceStatus',
}

interface ISetModalAppear {
  type: ActionTypes.SetModalAppear;
}

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

interface ISetBusinessInfo {
  type: ActionTypes.SetBusinessInfo;
  payload: {
    businessSet: IFormDataBusinessSet;
  };
}

interface ISetFoundingYear {
  type: ActionTypes.SetFoundingYear;
  payload: {
    foundingYear: string;
  };
}

interface ISetInsuranceStatus {
  type: ActionTypes.SetInsuranceStatus;
  payload: string;
}

// interface ISetFullTimeEmployees {
//   type: ActionTypes.SetFullTimeEmployees;
//   payload: {
//     fullTimeEmployees: number;
//   };
// }

interface ISetBusinessStructure {
  type: ActionTypes.SetBusinessStructure;
  payload: {
    businessStructure: BusinessStructure;
  };
}

interface ISetProfession {
  type: ActionTypes.SetProfession;
  payload: {
    profession: Job;
  };
}

interface ISetNoPrefillData {
  type: ActionTypes.SetNoPrefillData;
}

type Actions =
  | ISetLoading
  | ISetBusinessInfo
  | ISetModalAppear
  | ISetFoundingYear
  //| ISetFullTimeEmployees
  | ISetBusinessStructure
  | ISetProfession
  | ISetInsuranceStatus
  | ISetNoPrefillData;

export interface IFormDataBusinessSet {
  businessStructure: string;
  // fullTimeEmployees: string;
  // partTimeEmployees: string;
  foundingYear: string;
  insuranceStatus: string;
  businessOperations: string;
  businessEmployees: string;
  coverageLength: string;
}

export interface IFormBusinessData extends IFormDataBusinessSet {
  businessAddress: string;
  state: string;
  zipCode: string;
  city: string;
  businessName: string;
  coverageTypes: Coverage[];
  businessOwners: string;
}

export interface IFormData extends IFormBusinessData, IPersonalDetails {}

// state
export interface IPrefillState {
  prefill: boolean;
  loading: boolean;
  appear: boolean;
  coverageTypes: Coverage[];
  formData: Record<IFormDataBusinessSet>;
}

const persistedStore = getStore();

const record = Record<IPrefillState>(
  persistedStore?.prefill
    ? persistedStore?.prefill
    : {
        prefill: false,
        loading: true,
        appear: true,
        coverageTypes: [],
        formData: Record({
          foundingYear: '',
          //fullTimeEmployees: '',
          //partTimeEmployees: '',
          businessStructure: '',
          insuranceStatus: '',
          businessOperations: '',
          businessEmployees: '',
          coverageLength: CoverageLength.I_WANT_COVERAGE_FOR_THE_YEAR,
        })(),
      }
);

class PrefillState extends record implements IPrefillState {
  setBusinessInfo({
    businessStructure,
    //fullTimeEmployees,
    //partTimeEmployees,
    foundingYear,
    insuranceStatus,
    businessOperations,
    businessEmployees,
    coverageLength,
  }: IFormDataBusinessSet) {
    return (
      this.setIn(['formData', 'businessStructure'], businessStructure)
        //.setIn(['formData', 'businessStructure'], businessStructure)
        // .setIn(['formData', 'fullTimeEmployees'], fullTimeEmployees)
        // .setIn(['formData', 'partTimeEmployees'], partTimeEmployees)
        .setIn(['formData', 'foundingYear'], foundingYear)
        .setIn(['formData', 'insuranceStatus'], insuranceStatus)
        .setIn(['formData', 'businessOperations'], businessOperations)
        .setIn(['formData', 'businessEmployees'], businessEmployees)
        .setIn(['formData', 'coverageLength'], coverageLength)
    );
  }
}

// reducer
export default function reducer(state = new PrefillState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    case ActionTypes.SetBusinessInfo: {
      return state.setBusinessInfo(action.payload.businessSet);
    }

    case ActionTypes.SetModalAppear: {
      return state.set('appear', false);
    }

    case ActionTypes.SetFoundingYear: {
      return state.setIn(['formData', 'foundingYear'], action.payload.foundingYear);
    }

    case ActionTypes.SetInsuranceStatus: {
      return state.setIn(['formData', 'insuranceStatus'], action.payload);
    }

    // case ActionTypes.SetFullTimeEmployees: {
    //   return state.setIn(['formData', 'fullTimeEmployees'], action.payload.fullTimeEmployees);
    // }

    case ActionTypes.SetBusinessStructure: {
      return state.setIn(['formData', 'businessStructure'], action.payload.businessStructure);
    }

    case ActionTypes.SetProfession: {
      return state.setIn(['formData', 'profession'], action.payload.profession);
    }

    case ActionTypes.SetNoPrefillData: {
      return state.set('prefill', false);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setBusinessInfo = (businessSet: IFormDataBusinessSet): ISetBusinessInfo => ({
  type: ActionTypes.SetBusinessInfo,
  payload: {
    businessSet,
  },
});

export const setModalAppears = (): ISetModalAppear => ({
  type: ActionTypes.SetModalAppear,
});

// export const setFullTimeEmployees = (fullTimeEmployees: number): ISetFullTimeEmployees => ({
//   type: ActionTypes.SetFullTimeEmployees,
//   payload: {
//     fullTimeEmployees,
//   },
// });

export const setFoundingYear = (foundingYear: string): ISetFoundingYear => ({
  type: ActionTypes.SetFoundingYear,
  payload: {
    foundingYear,
  },
});

export const setInsuranceStatus = (insuranceStatus: string): ISetInsuranceStatus => ({
  type: ActionTypes.SetInsuranceStatus,
  payload: insuranceStatus,
});

export const setBusinessStructure = (businessStructure: BusinessStructure): ISetBusinessStructure => ({
  type: ActionTypes.SetBusinessStructure,
  payload: {
    businessStructure,
  },
});

export const setProfession = (profession: Job): ISetProfession => ({
  type: ActionTypes.SetProfession,
  payload: {
    profession,
  },
});

export const setNoPrefillData = (): ISetNoPrefillData => ({
  type: ActionTypes.SetNoPrefillData,
});

// selectors
export const getPrefill = (rooState: RootState): IPrefillState => rooState.get('prefill') as IPrefillState;

export const getCoverageTypes = (rooState: RootState): Coverage[] =>
  rooState.getIn(['prefill', 'coverageTypes']) as Coverage[];

export const getPrefillData = (rooState: RootState): IFormDataBusinessSet =>
  rooState.getIn(['prefill', 'formData']) as IFormDataBusinessSet;
