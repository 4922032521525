import { Record } from 'immutable';
import { Job } from '../types';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
export interface IJobSearchState {
  query: string;
  input: string;
  loading: boolean;
  jobs: Job[];
  jobTitle: string;
}

enum ActionTypes {
  SetInput = 'JobSearch/SetInput',
  SetQuery = 'JobSearch/SetQuery',
  SetLoading = 'JobSearch/SetLoading',
  SetJobs = 'JobSearch/SetJobs',
  SetJobTitle = 'JobSearch/SetJobTitle',
}

interface ISetInput {
  type: ActionTypes.SetInput;
  payload: {
    input: string;
  };
}

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

interface ISetQuery {
  type: ActionTypes.SetQuery;
  payload: {
    query: string;
  };
}

interface ISetJobs {
  type: ActionTypes.SetJobs;
  payload: {
    jobs: Job[];
  };
}

interface ISetJobTitle {
  type: ActionTypes.SetJobTitle;
  payload: {
    jobTitle: string;
  };
}

type Actions = ISetInput | ISetLoading | ISetQuery | ISetJobs | ISetJobTitle;

// state
const persistedStore = getStore();

const record = Record<IJobSearchState>(
  persistedStore?.jobSearch
    ? persistedStore.jobSearch
    : {
        query: '',
        input: '',
        loading: false,
        jobs: [],
        jobTitle: '',
      }
);

export class JobSearchState extends record implements IJobSearchState {}

// reducer
export default function reducer(state = new JobSearchState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetQuery: {
      return state.set('query', action.payload.query);
    }

    case ActionTypes.SetInput: {
      return state.set('input', action.payload.input);
    }

    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    case ActionTypes.SetJobs: {
      return state.set('jobs', action.payload.jobs);
    }

    case ActionTypes.SetJobTitle: {
      return state.set('jobTitle', action.payload.jobTitle);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setQuery = (query: string): ISetQuery => ({
  type: ActionTypes.SetQuery,
  payload: {
    query,
  },
});

export const setInput = (input: string): ISetInput => ({
  type: ActionTypes.SetInput,
  payload: {
    input,
  },
});

export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setJobs = (jobs: Job[]): ISetJobs => ({
  type: ActionTypes.SetJobs,
  payload: {
    jobs,
  },
});

export const setJobTitle = (jobTitle: string): ISetJobTitle => ({
  type: ActionTypes.SetJobTitle,
  payload: {
    jobTitle,
  },
});

// selectors
export const getJobSearch = (rooState: RootState): IJobSearchState =>
  rooState.get('jobSearch').toJS() as IJobSearchState;

export const getJob = (rootState: RootState): Job | null => {
  const { jobs, jobTitle } = getJobSearch(rootState);
  return jobs.find((x: Job) => x.label === jobTitle) || null;
};
