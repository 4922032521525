const palette = {
  white: 'white',
  surfaces: {
    primary: {
      1: '#f5feff',
      2: '#ebf9f9',
      3: '#e5f5f5',
      4: '#dff1f2',
      5: '#dcf0f0',
      6: '#d8edee',
    },
    secondary: {
      1: '#fcfaff',
      2: '#f5f2fa',
      3: '#f1edf7',
      4: '#ede8f3',
      5: '#ebe7f2',
      6: '#e8e3f0',
    },
  },
  primary: {
    0: '#f0fdfe',
    10: '#ddfbfd',
    20: '#b3eff4',
    30: '#6dd9df',
    40: '#32b5bd',
    50: '#288186',
    60: '#1c7378',
    70: '#0d5a5e',
    80: '#094c4e',
    90: '#03393a',
  },
  secondary: {
    0: '#f7f0ff',
    10: '#e9d9fc',
    20: '#cab1ec',
    30: '#ac90d5',
    40: '#8b6eb9',
    50: '#705995',
    60: '#593a88',
    70: '#3d1d6d',
    80: '#2b0d59',
    90: '#200845',
  },
  success: {
    10: '#caf0ca',
    20: '#a4e49f',
    30: '#73c865',
    40: '#5aae49',
    50: '#3d842a',
    60: '#479433',
    70: '#357528',
    80: '#295f1d',
    90: '#1d4614',
  },
  warning: {
    10: '#fff3c4',
    20: '#fadb5f',
    30: '#f7c948',
    40: '#fce588',
    50: '#f0b429',
    60: '#cb6e17',
    70: '#de911d',
    80: '#b44d12',
    90: '#8d2b0b',
  },
  error: {
    10: '#ffbdbd',
    20: '#ff9b9b',
    30: '#f86a6a',
    40: '#ef4e4e',
    50: '#e12d39',
    60: '#cf1124',
    70: '#ab091e',
    80: '#8a041a',
    90: '#610316',
  },
  grey: {
    10: '#f5f7fa',
    20: '#e4e7eb',
    30: '#cbd2d9',
    40: '#9aa5b1',
    50: '#7b8794',
    60: '#616e7c',
    70: '#52606d',
    80: '#3e4c59',
    90: '#323f4b',
    100: '#1f2933',
    110: '#F1F1F1',
  },
  text: {
    primary: '#0d0d0d',
    'high-emphasis': '#2c2c2c',
    'low-emphasis': '#6e6e6e',
    disabled: '#9e9e9e',
    link: '#0080F6',
  },
  chip: {
    background: '#4a006d',
    text: '#4a006d',
  },
};

export default palette;
