import { createTheme } from '@mui/material/styles';
import palette from './palette';

const OpenSans = ['Open Sans', 'sans-serif'].join(',');

const theme = createTheme({
  palette: {
    common: {
      black: palette.text.primary,
      white: palette.white,
    },
    grey: {
      100: palette.grey[10],
      200: palette.grey[20],
      300: palette.grey[30],
      400: palette.grey[40],
      500: palette.grey[50],
      600: palette.grey[60],
      700: palette.grey[70],
      800: palette.grey[80],
      900: palette.grey[90],
      A100: palette.grey[110],
    },
    primary: {
      main: palette.primary[50],
      dark: palette.primary[70],
      light: palette.primary[30],
      600: palette.primary[60],
    },
    info: {
      main: palette.primary[20],
      dark: palette.primary[30],
      light: palette.primary[10],
    },
    secondary: {
      A400: palette.secondary[50],
      100: palette.secondary[10],
      200: palette.secondary[20],
      300: palette.secondary[30],
      400: palette.secondary[40],
      500: palette.secondary[50],
      600: palette.secondary[60],
      700: palette.secondary[70],
      800: palette.secondary[80],
    },
    text: {
      primary: palette.text['high-emphasis'],
      secondary: palette.text['low-emphasis'],
      disabled: palette.text.disabled,
    },
  },
});

const customTheme = createTheme(theme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          fontFamily: OpenSans,
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: 0.16,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: palette.surfaces.secondary[3],
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: palette.surfaces.secondary[3],
          fontFamily: ['Open Sans', 'sans-serif'].join(','),
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: 0,
          paddingTop: 8,
          paddingBottom: 8,
          '&.Mui-selected, &:hover': {
            backgroundColor: `${palette.surfaces.secondary[6]} !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary[50],
          },
        },

        notchedOutline: {
          border: `1.5px solid ${palette.grey[50]}`,
          borderRadius: 8,
        },

        input: {
          padding: '12px 14px',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: theme.zIndex.modal,
          '&:not(.MuiBackdrop-invisible)': {
            backgroundColor: 'rgba(0, 0, 0, .8)',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            transform: 'translate(14px, 12px) scale(1)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          '&.MuiInputLabel-root': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: OpenSans,
          boxShadow: 'none !important',
          fontSize: 16,
          letterSpacing: '0.04em',
          borderRadius: 24,
          padding: '10px 25px',
          fontWeight: 600,

          '&.MuiLoadingButton-root': {
            boxShadow: 'none',
          },

          '&.MuiButton-sizeSmall': {
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: OpenSans,
          color: palette.text.primary,
          backgroundColor: 'white',

          '&.body-gray': {
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          margin: 0,
          background: palette.surfaces.secondary[3],
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: 2,

          '& .MuiAutocomplete-option': {
            fontFamily: ['Open Sans', 'sans-serif'].join(','),
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: 0,
            paddingTop: 8,
            paddingBottom: 8,
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            background: `${palette.surfaces.secondary[6]} !important`,
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: `${palette.surfaces.secondary[6]} !important`,
          },
        },
      },
    },
  },

  typography: {
    allVariants: {
      color: palette.text.primary,
    },
    h1: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      fontSize: 48,
      lineHeight: 1.08,
      fontWeight: 400,
    },
    h2: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      fontSize: 36,
      lineHeight: 1.22,
      fontWeight: 400,
    },
    h3: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      fontSize: 30,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      letterSpacing: 0,
      fontSize: 24,
      lineHeight: 1.17,
    },
    h5: {
      fontFamily: ['Roboto Slab', 'serif'].join(','),
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43,
    },
    body2: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0,
    },
    overline: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 10,
      lineHeight: 1.6,
      letterSpacing: 0.4,
    },
    caption: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.02em',
    },
  },
});

export default customTheme;
