import { Record } from 'immutable';
import { getStore } from '../utils/persist';
import { RootState } from './types';

// types
export type IQuote = any;

export interface IBpState {
  quotes: IQuote[];
  formID: string;
}

enum ActionTypes {
  SetQuotes = 'Bp/SetQuotes',
  SetFormID = 'Bp/SetFormID',
  SetApplicationData = 'Bp/SetApplicationData',
}

interface ISetFormID {
  type: ActionTypes.SetFormID;
  payload: {
    formID: string;
  };
}

interface ISetQuotes {
  type: ActionTypes.SetQuotes;
  payload: {
    quotes: IQuote[];
  };
}

interface ISetApplicationData {
  type: ActionTypes.SetApplicationData;
  payload: {
    quotes: IQuote[];
    formID: string;
  };
}

type Actions = ISetFormID | ISetQuotes | ISetApplicationData;

// state
const persistedStore = getStore();

const record = Record<IBpState>({
  quotes: persistedStore?.bp?.quotes || [],
  formID: persistedStore?.bp.formID || '',
});

export class BpState extends record implements IBpState {}

// reducer
export default function reducer(state = new BpState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetQuotes: {
      return state.set('quotes', action.payload.quotes);
    }

    case ActionTypes.SetFormID: {
      return state.set('formID', action.payload.formID);
    }

    case ActionTypes.SetApplicationData: {
      return state.set('quotes', action.payload.quotes).set('formID', action.payload.formID);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setQuotes = (quotes: IQuote[]): ISetQuotes => ({
  type: ActionTypes.SetQuotes,
  payload: {
    quotes,
  },
});

export const setFormID = (formID: string): ISetFormID => ({
  type: ActionTypes.SetFormID,
  payload: {
    formID,
  },
});

export const setApplicationData = (formID: string, quotes: IQuote[]): ISetApplicationData => ({
  type: ActionTypes.SetApplicationData,
  payload: {
    quotes,
    formID,
  },
});

// selectors
export const getQuotes = (rooState: RootState): IQuote[] => rooState.getIn(['bp', 'quotes']) as IQuote[];

export const getQuote: IQuote = (quoteID: string) => (rooState: RootState) =>
  getQuotes(rooState).find(q => q.id === quoteID || q.quoteNumber === quoteID);

export const getFormID = (rooState: RootState): string => rooState.getIn(['bp', 'formID']) as string;
