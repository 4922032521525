import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
export interface IIntercomState {
  loading: boolean;
  visible: boolean;
  conversationID: string;
}

enum ActionTypes {
  SetLoading = 'Intercom/SetLoading',
  SetVisible = 'Intercom/SetVisible',
  SetConversationID = 'Intercom/SetConversationID',
}

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

interface ISetVisible {
  type: ActionTypes.SetVisible;
  payload: {
    visible: boolean;
  };
}

interface ISetConversationID {
  type: ActionTypes.SetConversationID;
  payload: {
    conversationID: string;
  };
}

type Actions = ISetLoading | ISetVisible | ISetConversationID;

// state
const persistedStore = getStore();

const record = Record<IIntercomState>(
  persistedStore?.intercom
    ? persistedStore.intercom
    : {
        loading: false,
        visible: false,
        conversationID: '',
      }
);

export class IntercomState extends record implements IIntercomState {}

// reducer
export default function reducer(state = new IntercomState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    case ActionTypes.SetVisible: {
      return state.set('visible', action.payload.visible);
    }

    case ActionTypes.SetConversationID: {
      return state.set('conversationID', action.payload.conversationID);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setVisible = (visible: boolean): ISetVisible => ({
  type: ActionTypes.SetVisible,
  payload: {
    visible,
  },
});

export const setConversationID = (conversationID: string): ISetConversationID => ({
  type: ActionTypes.SetConversationID,
  payload: {
    conversationID,
  },
});

// selectors
export const getIntercom = (rooState: RootState): IIntercomState => rooState.get('intercom').toJS();
