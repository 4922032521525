import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';

export class Driver {
  active: boolean;
  answerPoolId: string;
  questionGroupId: string;
  firstName?: string;
  lastName?: string;
  sameAsOwner?: string;
  dateOfBirth?: string;
  licenseStatus?: string;
  licenseState?: string;
  firstLicenseAge?: string;
  gender?: string;
  maritalStatus?: string;
}

// types
export interface IDriverState {
  drivers: Driver[];
}

enum ActionTypes {
  AddDriver = 'Driver/AddDriver',
  RemoveDriver = 'Vehicle/RemoveDriver',
  UpdateDriver = 'Driver/UpdateDriver',
  SetActiveDriver = 'Driver/SetActiveDriver',
}

interface IAddDriver {
  type: ActionTypes.AddDriver;
  payload: {
    driver: Driver;
  };
}

interface IUpdateDriver {
  type: ActionTypes.UpdateDriver;
  payload: {
    driver: Driver;
  };
}

interface IRemoveDriver {
  type: ActionTypes.RemoveDriver;
  payload: {
    answerPoolId: string;
  };
}

interface ISetActiveDriver {
  type: ActionTypes.SetActiveDriver;
  payload: {
    answerPoolId: string;
  };
}

type Actions = IAddDriver | IUpdateDriver | ISetActiveDriver | IRemoveDriver;

const persistedStore = getStore();

// state
const record = Record<IDriverState>(persistedStore?.driver ? persistedStore.driver : { drivers: [] });

class DriverState extends record implements IDriverState {}

// reducer
export default function reducer(state = new DriverState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.AddDriver: {
      const alreadyAddedDrivers = state.get('drivers');
      if (alreadyAddedDrivers.find(v => v.answerPoolId === action.payload.driver.answerPoolId)) {
        return state;
      }
      return state.set('drivers', [...alreadyAddedDrivers, action.payload.driver]);
    }
    case ActionTypes.UpdateDriver: {
      const alreadyAddedDrivers = state.get('drivers');
      const index = alreadyAddedDrivers.findIndex(v => v.answerPoolId === action.payload.driver.answerPoolId);
      alreadyAddedDrivers[index] = action.payload.driver;
      return state.set('drivers', [...alreadyAddedDrivers]);
    }
    case ActionTypes.SetActiveDriver: {
      const alreadyAddedDrivers = state.get('drivers');
      alreadyAddedDrivers.forEach(v => (v.active = false));
      const index = alreadyAddedDrivers.findIndex(v => v.answerPoolId === action.payload.answerPoolId);
      if (index >= 0) {
        alreadyAddedDrivers[index].active = true;
      }
      return state.set('drivers', [...alreadyAddedDrivers]);
    }
    case ActionTypes.RemoveDriver: {
      const alreadyAddedDrivers = state.get('drivers');
      return state.set(
        'drivers',
        alreadyAddedDrivers.filter(driver => driver.answerPoolId !== action.payload.answerPoolId)
      );
    }
    default: {
      return state;
    }
  }
}

// actions
export const addDriver = (driver: Driver): IAddDriver => ({
  type: ActionTypes.AddDriver,
  payload: {
    driver,
  },
});

export const updateDriver = (driver: Driver): IUpdateDriver => ({
  type: ActionTypes.UpdateDriver,
  payload: {
    driver,
  },
});

export const setActiveDriver = (answerPoolId: string): ISetActiveDriver => ({
  type: ActionTypes.SetActiveDriver,
  payload: {
    answerPoolId,
  },
});

export const removeDriver = (answerPoolId: string): IRemoveDriver => ({
  type: ActionTypes.RemoveDriver,
  payload: {
    answerPoolId,
  },
});

// selectors
export const getDrivers = (rooState: RootState): Driver[] => rooState.getIn(['driver', 'drivers']) as Driver[];
