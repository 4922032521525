import { IQuote } from '../bp';
import { ActionTypes } from './types';

export interface ISetSelectedQuote {
  type: ActionTypes.SetSelectedQuote;
  payload: {
    selectedQuote: IQuote;
  };
}

export interface ISetPurchasedPolicyDetails {
  type: ActionTypes.SetPurchasedPolicyDetails;
  payload: {
    purchasedPolicyDetails: any;
  };
}

export interface ISetPaymentOption {
  type: ActionTypes.SetPaymentOption;
  payload: {
    paymentOption: PaymentOptions;
  };
}

export interface ICoterieState {
  selectedQuote: IQuote;
  paymentOption: PaymentOptions | null;
  purchasedPolicyDetails: any;
}

export enum PaymentOptions {
  Monthly = 'PAY_MONTHLY',
  Yearly = 'PAY_YEARLY',
}
