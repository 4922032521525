import { Record } from 'immutable';
import { Coverage, NumberOrString } from '../types';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
enum ActionTypes {
  SetFirstName = 'User/SetFirstName',
  SetLastName = 'User/SetLastName',
  SetPhoneNumber = 'User/SetPhoneNumber',
  SetEmailAddress = 'User/SetEmailAddress',
  SetToken = 'User/SetToken',
  SetSelectedCoverage = 'User/SetSelectedCoverage',
  SetCoverageNeeded = 'User/SetCoverageNeeded',
  SetIP = 'User/SetIP',
  SetYearsOfExperience = 'User/SetYearsOfExperience',
  SetUserId = 'User/SetUserId',
}

interface ISetFirstName {
  type: ActionTypes.SetFirstName;
  payload: {
    firstName: string;
  };
}

interface ISetLastName {
  type: ActionTypes.SetLastName;
  payload: {
    lastName: string;
  };
}

interface ISetPhoneNumber {
  type: ActionTypes.SetPhoneNumber;
  payload: {
    phoneNumber: string;
  };
}

interface ISetEmailAddress {
  type: ActionTypes.SetEmailAddress;
  payload: {
    emailAddress: string;
  };
}

interface ISetToken {
  type: ActionTypes.SetToken;
  payload: {
    token: string;
  };
}

interface ISetUserId {
  type: ActionTypes.SetUserId;
  payload: {
    userId: string;
  };
}

interface ISetCoverageNeeded {
  type: ActionTypes.SetCoverageNeeded;
  payload: {
    coverageNeeded: Coverage[];
  };
}

interface ISetIP {
  type: ActionTypes.SetIP;
  payload: {
    ip: string;
  };
}

interface ISetYearsOfExperience {
  type: ActionTypes.SetYearsOfExperience;
  payload: {
    yearsOfExperience: NumberOrString;
  };
}

type Actions =
  | ISetFirstName
  | ISetLastName
  | ISetPhoneNumber
  | ISetEmailAddress
  | ISetYearsOfExperience
  | ISetToken
  | ISetCoverageNeeded
  | ISetIP
  | ISetUserId;

export interface IFormDataPersonalSet {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  yearsOfExperience: NumberOrString;
}

// state
export interface IPersonalDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  yearsOfExperience: NumberOrString;
}

export interface IUserState {
  personalDetails: IPersonalDetails;
  token: string;
  userId: string;
  coverageNeeded: Coverage[];
  ip: string;
}

const persistedStore = getStore();

const record = Record<IUserState>(
  persistedStore?.user
    ? persistedStore.user
    : {
        personalDetails: { firstName: '', lastName: '', phoneNumber: '', emailAddress: '', yearsOfExperience: '' },
        token: '',
        userId: '',
        coverageNeeded: [],
        ip: '',
      }
);

class UserState extends record implements IUserState {}

// reducer
export default function reducer(state = new UserState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetFirstName: {
      return state.setIn(['personalDetails', 'firstName'], action.payload.firstName);
    }

    case ActionTypes.SetLastName: {
      return state.setIn(['personalDetails', 'lastName'], action.payload.lastName);
    }

    case ActionTypes.SetPhoneNumber: {
      return state.setIn(['personalDetails', 'phoneNumber'], action.payload.phoneNumber);
    }

    case ActionTypes.SetEmailAddress: {
      return state.setIn(['personalDetails', 'emailAddress'], action.payload.emailAddress);
    }

    case ActionTypes.SetToken: {
      return state.set('token', action.payload.token);
    }

    case ActionTypes.SetUserId: {
      return state.set('userId', action.payload.userId);
    }

    case ActionTypes.SetCoverageNeeded: {
      return state.set('coverageNeeded', action.payload.coverageNeeded);
    }

    case ActionTypes.SetIP: {
      return state.set('ip', action.payload.ip);
    }

    case ActionTypes.SetYearsOfExperience: {
      return state.setIn(['personalDetails', 'yearsOfExperience'], action.payload.yearsOfExperience);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setFirstName = (firstName: string): ISetFirstName => ({
  type: ActionTypes.SetFirstName,
  payload: {
    firstName,
  },
});

export const setLastName = (lastName: string): ISetLastName => ({
  type: ActionTypes.SetLastName,
  payload: {
    lastName,
  },
});

export const setPhoneNumber = (phoneNumber: string): ISetPhoneNumber => ({
  type: ActionTypes.SetPhoneNumber,
  payload: {
    phoneNumber,
  },
});

export const setEmailAddress = (emailAddress: string): ISetEmailAddress => ({
  type: ActionTypes.SetEmailAddress,
  payload: {
    emailAddress,
  },
});

export const setToken = (token: string): ISetToken => ({
  type: ActionTypes.SetToken,
  payload: {
    token,
  },
});

export const setUserId = (userId: string): ISetUserId => ({
  type: ActionTypes.SetUserId,
  payload: {
    userId,
  },
});

export const setCoverageNeeded = (coverageNeeded: Coverage[]): ISetCoverageNeeded => ({
  type: ActionTypes.SetCoverageNeeded,
  payload: {
    coverageNeeded,
  },
});

export const setIP = (ip: string): ISetIP => ({
  type: ActionTypes.SetIP,
  payload: {
    ip,
  },
});

export const setYearsOfExperience = (yearsOfExperience: NumberOrString): ISetYearsOfExperience => ({
  type: ActionTypes.SetYearsOfExperience,
  payload: {
    yearsOfExperience,
  },
});

// selectors
export const getUser = (rooState: RootState): IUserState => rooState.get('user').toJS();
export const getUserIP = (rooState: RootState): string => rooState.get('user').get;
export const getCoverageNeeded = (rooState: RootState): Coverage[] =>
  rooState.getIn(['user', 'coverageNeeded']) as Coverage[];
export const getUserToken = (rooState: RootState): string => rooState.getIn(['user', 'token']) as string;
export const getUserId = (rooState: RootState): string => rooState.getIn(['user', 'userId']) as string;
export const getUserPersonalDetails = (rooState: RootState): IPersonalDetails =>
  rooState.getIn(['user', 'personalDetails']) as IPersonalDetails;
