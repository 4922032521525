import moment, { Moment } from 'moment';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_DISPLAY_FORMAT = 'MM.DD.YYYY';
export const getToday = () => moment().format(DATE_FORMAT);
export const getTomorrow = () => moment().add(1, 'days').format(DATE_FORMAT);
export const toMoment = (date: string) => moment(date, DATE_FORMAT);

export const toString = (date: Moment | null | string) => {
  if (date === null) return '';
  if (moment.isMoment(date)) return date.format(DATE_FORMAT);

  return date;
};

export const isPastDate = (date: string) => moment(date, DATE_FORMAT).isBefore(moment(), 'day');

export const toDisplayFormat = (date: string) => moment(date, DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
