import { Record } from 'immutable';
import { NumberOrString } from '../types';
import { getTomorrow } from '../utils/date';
import { getStore } from '../utils/persist';
import { RootState } from './types';

// types
export interface IBusinessDetailsFormData {
  grossRevenue: NumberOrString;
  //yearsOfExperience: NumberOrString;
  fullTimeEmployees: NumberOrString;
  partTimeEmployees: NumberOrString;
  payrollOwner: NumberOrString;
  payrollEmployees: NumberOrString;
  payrollContractors: NumberOrString;
}

export interface IEffectiveDateFormData {
  effectiveDate: string;
}

export interface IBodilyInjuryFormData {
  bodilyInjury: string;
}

export interface IBuildingFormData {
  buildingOwnership: string;
  buildingRelationship: string;
  buildingConstruction: string;
  buildingHasSprinkler: string;
  areaOccupied: NumberOrString;
  totalArea: NumberOrString;
  stories: NumberOrString;
  constructionYear: NumberOrString;
  bppLimit: NumberOrString;
  fireProtection: string;
  securitySystem: string;
}

export interface IFeinFormData {
  fein?: string;
  ssn?: string;
  has_fein: string;
}

export interface IModelerState
  extends IBusinessDetailsFormData,
    IEffectiveDateFormData,
    IBodilyInjuryFormData,
    IBuildingFormData,
    IFeinFormData {}

enum ActionTypes {
  SetBusinessDetailsFormData = 'Modeler/SetBusinessDetailsFormData',
  SetEffectiveDate = 'Modeler/SetEffectiveDate',
  SetBodilyInjury = 'Modeler/SetBodilyInjury',
  SetFeinData = 'Modeler/SetFeinData',
  SetBuildingData = 'Modeler/SetBuildingData',
}

interface ISetBusinessDetailsFormData {
  type: ActionTypes.SetBusinessDetailsFormData;
  payload: {
    formData: IBusinessDetailsFormData;
  };
}

interface ISetEffectiveDate {
  type: ActionTypes.SetEffectiveDate;
  payload: {
    effectiveDate: string;
  };
}

interface ISetBodilyInjury {
  type: ActionTypes.SetBodilyInjury;
  payload: {
    bodilyInjury: string;
  };
}

interface ISetFeinData {
  type: ActionTypes.SetFeinData;
  payload: {
    formData: IFeinFormData;
  };
}

interface ISetBuildingData {
  type: ActionTypes.SetBuildingData;
  payload: {
    formData: IBuildingFormData;
  };
}

type Actions = ISetBusinessDetailsFormData | ISetEffectiveDate | ISetBodilyInjury | ISetFeinData | ISetBuildingData;

// state
const persistedState: any = getStore();

const record = Record<IModelerState>(
  persistedState?.modeler
    ? persistedState?.modeler
    : {
        grossRevenue: '' as NumberOrString,
        //yearsOfExperience: '' as NumberOrString,
        fullTimeEmployees: '' as NumberOrString,
        partTimeEmployees: '' as NumberOrString,
        payrollOwner: '' as NumberOrString,
        payrollEmployees: '' as NumberOrString,
        payrollContractors: '' as NumberOrString,
        effectiveDate: getTomorrow(),
        bodilyInjury: '',
        fein: '',
        ssn: '',
        has_fein: 'Yes',
        buildingRelationship: '',
        buildingConstruction: '',
        areaOccupied: '',
        totalArea: '',
        stories: '',
        constructionYear: '',
        bppLimit: '',
        buildingOwnership: '',
        buildingHasSprinkler: '',
        fireProtection: '',
        securitySystem: '',
      }
);

class ModelerState extends record implements IModelerState {}

// reducer
export default function reducer(state = new ModelerState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetBusinessDetailsFormData: {
      return (
        state
          .set('grossRevenue', action.payload.formData.grossRevenue)
          //.set('yearsOfExperience', action.payload.formData.yearsOfExperience)
          .set('fullTimeEmployees', action.payload.formData.fullTimeEmployees)
          .set('partTimeEmployees', action.payload.formData.partTimeEmployees)
          .set('payrollOwner', action.payload.formData.payrollOwner)
          .set('payrollEmployees', action.payload.formData.payrollEmployees)
          .set('payrollContractors', action.payload.formData.payrollContractors)
      );
    }

    case ActionTypes.SetEffectiveDate: {
      return state.set('effectiveDate', action.payload.effectiveDate);
    }

    case ActionTypes.SetBodilyInjury: {
      return state.set('bodilyInjury', action.payload.bodilyInjury);
    }

    case ActionTypes.SetFeinData: {
      return state
        .set('fein', action.payload.formData.fein)
        .set('ssn', action.payload.formData.ssn)
        .set('has_fein', action.payload.formData.has_fein);
    }

    case ActionTypes.SetBuildingData: {
      return state
        .set('buildingOwnership', action.payload.formData.buildingOwnership)
        .set('buildingRelationship', action.payload.formData.buildingRelationship)
        .set('buildingConstruction', action.payload.formData.buildingConstruction)
        .set('buildingHasSprinkler', action.payload.formData.buildingHasSprinkler)
        .set('areaOccupied', action.payload.formData.areaOccupied)
        .set('totalArea', action.payload.formData.totalArea)
        .set('stories', action.payload.formData.stories)
        .set('constructionYear', action.payload.formData.constructionYear)
        .set('bppLimit', action.payload.formData.bppLimit)
        .set('fireProtection', action.payload.formData.fireProtection)
        .set('securitySystem', action.payload.formData.securitySystem);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setBusinessDetailsFormData = (formData: IBusinessDetailsFormData): ISetBusinessDetailsFormData => ({
  type: ActionTypes.SetBusinessDetailsFormData,
  payload: { formData },
});

export const setEffectiveDate = (effectiveDate: string): ISetEffectiveDate => ({
  type: ActionTypes.SetEffectiveDate,
  payload: {
    effectiveDate,
  },
});

export const setBodilyInjury = (bodilyInjury: string): ISetBodilyInjury => ({
  type: ActionTypes.SetBodilyInjury,
  payload: {
    bodilyInjury,
  },
});

export const setFeinData = (formData: IFeinFormData): ISetFeinData => ({
  type: ActionTypes.SetFeinData,
  payload: {
    formData,
  },
});

export const setBuildingData = (formData: IBuildingFormData): ISetBuildingData => ({
  type: ActionTypes.SetBuildingData,
  payload: {
    formData,
  },
});

// selectors
export const getModeler = (rooState: RootState): IModelerState => rooState.get('modeler') as IModelerState;
export const getEffectiveDate = (rootState: RootState): string =>
  rootState.getIn(['modeler', 'effectiveDate']) as string;

export const getBodilyInjury = (rootState: RootState) => rootState.getIn(['modeler', 'bodilyInjury']) as string;

export const getBusinessDetails = (rooState: RootState): IBusinessDetailsFormData => ({
  grossRevenue: rooState.getIn(['modeler', 'grossRevenue']) as number,
  //yearsOfExperience: rooState.getIn(['modeler', 'yearsOfExperience']) as number,
  fullTimeEmployees: rooState.getIn(['modeler', 'fullTimeEmployees']) as string,
  partTimeEmployees: rooState.getIn(['modeler', 'partTimeEmployees']) as string,
  payrollOwner: rooState.getIn(['modeler', 'payrollOwner']) as number,
  payrollEmployees: rooState.getIn(['modeler', 'payrollEmployees']) as number,
  payrollContractors: rooState.getIn(['modeler', 'payrollContractors']) as number,
});

export const getFeinData = (rooState: RootState): IFeinFormData => ({
  fein: rooState.getIn(['modeler', 'fein']) as string,
  ssn: rooState.getIn(['modeler', 'ssn']) as string,
  has_fein: rooState.getIn(['modeler', 'has_fein']) as string,
});

export const getBuildingData = (rooState: RootState): IBuildingFormData => ({
  buildingOwnership: rooState.getIn(['modeler', 'buildingOwnership']) as string,
  buildingRelationship: rooState.getIn(['modeler', 'buildingRelationship']) as string,
  buildingConstruction: rooState.getIn(['modeler', 'buildingConstruction']) as string,
  buildingHasSprinkler: rooState.getIn(['modeler', 'buildingHasSprinkler']) as string,
  areaOccupied: rooState.getIn(['modeler', 'areaOccupied']) as NumberOrString,
  totalArea: rooState.getIn(['modeler', 'totalArea']) as NumberOrString,
  stories: rooState.getIn(['modeler', 'stories']) as NumberOrString,
  constructionYear: rooState.getIn(['modeler', 'constructionYear']) as NumberOrString,
  bppLimit: rooState.getIn(['modeler', 'bppLimit']) as NumberOrString,
  fireProtection: rooState.getIn(['modeler', 'fireProtection']) as string,
  securitySystem: rooState.getIn(['modeler', 'securitySystem']) as string,
});
