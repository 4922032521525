import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';
import { InfoType } from '../types';

export class Vehicle {
  vin?: string;
  year?: number;
  make?: string;
  model?: string;
  answerPoolId: string;
  questionGroupId: string;
  infoType: InfoType;
  configuration?: string;
  active?: boolean;
  usage?: string;
  distance?: string;
  jobSites?: string;
  comprehensiveDeductible?: string;
  collisionDeductible?: string;
  garagesameaddress?: string;
  garageAddress1?: string;
  garageAddressCity?: string;
  garageAddressState?: string;
  garageAddressZip?: string;
  ownership?: string;
  additionalInterestType?: string;
  additionalName?: string;
  additionalEmail?: string;
  additionalPhone?: string;
  additionalAddress?: string;
  additionalAddressCity?: string;
  additionalAddressState?: string;
  additionalAddressZip?: string;
  anchor?: string;
  dailyUsage?: string;
  driversExp?: string;
}

// types
export interface IVehicleState {
  vehicles: Vehicle[];
}

enum ActionTypes {
  AddVehicle = 'Vehicle/AddVehicle',
  RemoveVehicle = 'Vehicle/RemoveVehicle',
  UpdateVehicle = 'Vehicle/UpdateVehicle',
  SetActiveVehicle = 'Vehicle/SetActiveVehicle',
}

interface IAddVehicle {
  type: ActionTypes.AddVehicle;
  payload: {
    vehicle: Vehicle;
  };
}

interface IUpdateVehicle {
  type: ActionTypes.UpdateVehicle;
  payload: {
    vehicle: Vehicle;
  };
}

interface ISetActiveVehicle {
  type: ActionTypes.SetActiveVehicle;
  payload: {
    answerPoolId: string;
  };
}

interface IRemoveVehicle {
  type: ActionTypes.RemoveVehicle;
  payload: {
    answerPoolId: string;
  };
}

type Actions = IAddVehicle | IUpdateVehicle | ISetActiveVehicle | IRemoveVehicle;

const persistedStore = getStore();

// state
const record = Record<IVehicleState>(persistedStore?.vehicle ? persistedStore.vehicle : { vehicles: [] });

class VehicleState extends record implements IVehicleState {}

// reducer
export default function reducer(state = new VehicleState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.AddVehicle: {
      const alreadyAddedVehicles = state.get('vehicles');
      if (alreadyAddedVehicles.find(v => v.answerPoolId === action.payload.vehicle.answerPoolId)) {
        return state;
      }
      return state.set('vehicles', [...alreadyAddedVehicles, action.payload.vehicle]);
    }
    case ActionTypes.UpdateVehicle: {
      const alreadyAddedVehicles = state.get('vehicles');
      const index = alreadyAddedVehicles.findIndex(v => v.answerPoolId === action.payload.vehicle.answerPoolId);
      alreadyAddedVehicles[index] = action.payload.vehicle;
      return state.set('vehicles', [...alreadyAddedVehicles]);
    }
    case ActionTypes.SetActiveVehicle: {
      const alreadyAddedVehicles = state.get('vehicles');
      alreadyAddedVehicles.forEach(v => (v.active = false));
      const index = alreadyAddedVehicles.findIndex(v => v.answerPoolId === action.payload.answerPoolId);
      if (index >= 0) {
        alreadyAddedVehicles[index].active = true;
      }
      return state.set('vehicles', [...alreadyAddedVehicles]);
    }
    case ActionTypes.RemoveVehicle: {
      const alreadyAddedVehicles = state.get('vehicles');
      return state.set(
        'vehicles',
        alreadyAddedVehicles.filter(vehicle => vehicle.answerPoolId !== action.payload.answerPoolId)
      );
    }
    default: {
      return state;
    }
  }
}

// actions
export const addVehicle = (vehicle: Vehicle): IAddVehicle => ({
  type: ActionTypes.AddVehicle,
  payload: {
    vehicle,
  },
});

export const updateVehicle = (vehicle: Vehicle): IUpdateVehicle => ({
  type: ActionTypes.UpdateVehicle,
  payload: {
    vehicle,
  },
});

export const setActiveVehicle = (answerPoolId: string): ISetActiveVehicle => ({
  type: ActionTypes.SetActiveVehicle,
  payload: {
    answerPoolId,
  },
});

export const removeVehicle = (answerPoolId: string): IRemoveVehicle => ({
  type: ActionTypes.RemoveVehicle,
  payload: {
    answerPoolId,
  },
});

// selectors
export const getVehicles = (rooState: RootState): Vehicle[] => rooState.getIn(['vehicle', 'vehicles']) as Vehicle[];
