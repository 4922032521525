import { Suspense, lazy, useEffect, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { getIntercom } from './redux/intercom';
import { useSelector } from 'react-redux';
import { postWindowCloseMessage } from './utils/requests';
import SuspenseRouter from './suspense-router';

const ErrorMessage = lazy(() => import('./components/error-message'));
const Home = lazy(() => import('./pages/home'));
const BasicDetails = lazy(() => import('./components/modals/basic-details'));
const Prefill = lazy(() => import('./pages/prefill'));
const EffectiveDate = lazy(() => import('./components/modals/effective-date'));
const CarrierQuotes = lazy(() => import('./pages/carrier-quotes'));
const CarrierDetails = lazy(() => import('./pages/carrier-details'));
const CarrierQuotesMain = lazy(() => import('./pages/carrier-quotes/carrier-quotes-main'));
const CoteriePaymentFlow = lazy(() => import('./pages/coterie'));
const ReviewOrderCoterie = lazy(() => import('./pages/coterie/review-order'));
const JobSearch = lazy(() => import('./components/modals/job-search'));
const OrderConfirmationCoterie = lazy(() => import('./pages/coterie/confirmation'));
const Additional = lazy(() => import('./pages/additional'));
const BusinessFormSet = lazy(() => import('./components/modals/prefill/business-info-details'));
const BusinessLocationFormSet = lazy(() => import('./components/modals/prefill/business-info-location'));
const PersonalFormSet = lazy(() => import('./components/modals/prefill/business-info-personal-details'));
const BusinessOperations = lazy(() => import('./components/modals/business-info-operations'));
const CABodilyInjury = lazy(() => import('./components/modals/ca-bodily-injury'));
const CarrierQuestions = lazy(() => import('./pages/carrier-questions'));
const VehicleInformation = lazy(() => import('./pages/vehicle-information'));
const FeinInfo = lazy(() => import('./components/modals/fein-info'));
const BuildingInfo = lazy(() => import('./components/modals/building-info'));
const CoverageFormSet = lazy(() => import('./pages/coverage'));
const BPFormLoader = lazy(() => import('./pages/bp-form-loader'));
const QuotesLoading = lazy(() => import('./pages/quotes-loading'));
const VehicleUse = lazy(() => import('./pages/vehicle-use'));
const VehicleGarage = lazy(() => import('./pages/vehicle-garage'));
const VehicleOwnership = lazy(() => import('./pages/vehicle-ownership'));
const VehicleList = lazy(() => import('./pages/vehicle-list'));
const DriverInformation = lazy(() => import('./pages/driver-information'));
const DriverList = lazy(() => import('./pages/driver-list'));
const CarrierSpecificQuestion = lazy(() => import('./components/carrier-specific-question'));

export default function App() {
  const init = async () => {
    if (process.env.REACT_APP_USE_COUNTERS === '1') {
      TagManager.initialize({
        gtmId: 'GTM-5G754CZ',
      });
    }
    if (sessionStorage.getItem('unloaded')) {
      sessionStorage.removeItem('unloaded');
      await postWindowCloseMessage(true);
    } else {
      //@ts-ignore
      delete_cookie('intercom-session-ggekus3b', '', '');
      delete_cookie('intercom-id-ggekus3b', '', '');
      delete_cookie('intercom-device-id-ggekus3b', '', '');
      localStorage.removeItem('intercom.intercom-state-ggekus3b');
    }
  };

  const intercom = useSelector(getIntercom);

  useMemo(() => {
    const elements = document.getElementsByClassName('intercom-messenger-frame');
    if (elements[0]) {
      if (intercom.visible) {
        (elements[0] as any).classList.remove('remove-intercom');
      } else {
        (elements[0] as any).classList.add('remove-intercom');
      }
    }
  }, [intercom]);

  const get_cookie = (name: string) => {
    return document.cookie.split(';').some(c => {
      return c.trim().startsWith(name + '=');
    });
  };

  const delete_cookie = (name: string, path: string, domain: string) => {
    if (get_cookie(name)) {
      document.cookie =
        name +
        '=' +
        (path ? ';path=' + path : '') +
        (domain ? ';domain=' + domain : '') +
        ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  };

  useEffect(() => {
    init();
    window.addEventListener('beforeunload', async e => {
      sessionStorage.setItem('unloaded', 'true');
      await postWindowCloseMessage(false);
    });
    return () => {
      window.removeEventListener('beforeunload', () => {
        sessionStorage.setItem('unloaded', 'true');
      });
    };
  }, []);

  return (
    <>
      <ErrorMessage />
      <SuspenseRouter window={window}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home isNew={true} />}>
              <Route index element={<CoverageFormSet isNew={true} />} />
              <Route element={<Prefill />}>
                <Route path="/business-information" element={<BusinessFormSet />} />
                <Route path="/business-location" element={<BusinessLocationFormSet />} />
                <Route path="/personal-information" element={<PersonalFormSet />} />
              </Route>
              <Route path="jobs" element={<JobSearch />} />
              <Route path="operations" element={<BusinessOperations />} />
              <Route path="business-size" element={<BasicDetails />} />
              <Route path="effective-date" element={<EffectiveDate />} />
              <Route path="bodily-injury" element={<CABodilyInjury />} />
              <Route path="fein-info" element={<FeinInfo />} />
              <Route path="building-info" element={<BuildingInfo />} />
            </Route>
            <Route path="/" element={<Additional />}>
              <Route path="/form-submit" element={<BPFormLoader />} />
              <Route path="/vehicle-information" element={<VehicleInformation />} />
              <Route path="/vehicle-use" element={<VehicleUse />} />
              <Route path="/vehicle-garage" element={<VehicleGarage />} />
              <Route path="/vehicle-ownership" element={<VehicleOwnership />} />
              <Route path="/vehicle-list" element={<VehicleList />} />
              <Route path="/driver-information" element={<DriverInformation />} />
              <Route path="/driver-list" element={<DriverList />} />
              <Route path="/quotes-loading" element={<QuotesLoading />} />
            </Route>
            <Route path="/carrier-questions" element={<CarrierQuestions />}>
              <Route path=":code" element={<CarrierSpecificQuestion />} />
            </Route>
            <Route path="/carrier-quotes" element={<CarrierQuotes />}>
              <Route index element={<CarrierQuotesMain />} />
              <Route path=":id/:bypass" element={<CarrierDetails />} />
            </Route>
            <Route path="/purchase/coterie" element={<CoteriePaymentFlow />}>
              <Route path="review-order" element={<ReviewOrderCoterie />}></Route>
              <Route path="confirmation" element={<OrderConfirmationCoterie />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </SuspenseRouter>
    </>
  );
}
