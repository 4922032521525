import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
export interface ISourceState {
  states: {
    name: string;
    isoCode: string;
  }[];
  jobs: Jobs;
}

export type Jobs = {
  [key: string]: string;
};

enum ActionTypes {
  SetJobs = 'Source/SetJobs',
  SetStates = 'Source/SetStates',
}

interface ISetJobs {
  type: ActionTypes.SetJobs;
  payload: {
    jobs: {
      [key: string]: string;
    };
  };
}

interface ISetStates {
  type: ActionTypes.SetStates;
  payload: {
    states: {
      name: string;
      isoCode: string;
    }[];
  };
}

type Actions = ISetJobs | ISetStates;

const persistedStore = getStore();

// state
const record = Record<ISourceState>(
  persistedStore?.source
    ? persistedStore.source
    : {
        states: [],
        jobs: {},
      }
);

class SourceState extends record implements ISourceState {}

// reducer
export default function reducer(state = new SourceState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetJobs: {
      return state.set('jobs', action.payload.jobs);
    }

    case ActionTypes.SetStates: {
      return state.set('states', action.payload.states);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setJobs = (jobs: { [key: string]: string }): ISetJobs => ({
  type: ActionTypes.SetJobs,
  payload: {
    jobs,
  },
});

export const setStates = (states: { name: string; isoCode: string }[]): ISetStates => ({
  type: ActionTypes.SetStates,
  payload: {
    states,
  },
});

// selectors
export const getJobs = (rooState: RootState): Jobs => rooState.getIn(['source', 'jobs']) as Jobs;
export const getStates = (
  rooState: RootState
): {
  name: string;
  isoCode: string;
}[] =>
  rooState.getIn(['source', 'states']) as {
    name: string;
    isoCode: string;
  }[];
