import { Record } from 'immutable';
import { getStore } from '../utils/persist';
import { RootState } from './types';

// types
export interface IExclusionState {
  bucket: ExclusionCollection;
  carrier: ExclusionCollection;
  loading: boolean;
}

export interface ExclusionCollection {
  exclusion: ExclusionMap;
  jobLabel: string;
  loaded: boolean;
}

export interface Exclusion {
  title: string;
  description: string;
}

export enum ExclusionType {
  BUCKET = 'bucket',
  CARRIER = 'carrier',
}

enum ActionTypes {
  SetExclusions = 'Exclusion/SetExclusions',
  SetLoading = 'Exclusion/SetLoading',
}

interface ISetExclusions {
  type: ActionTypes.SetExclusions;
  payload: {
    type: ExclusionType;
    exclusions: ExclusionMap;
    jobLabel: string;
  };
}

export type ExclusionMap = { [name: string]: Array<Exclusion> };

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

type Actions = ISetExclusions | ISetLoading;

const persistedStore = getStore();

const record = Record<IExclusionState>({
  bucket: persistedStore?.exclusion?.bucket || {
    exclusion: {},
    jobLabel: '',
    loaded: false,
  },
  carrier: persistedStore?.exclusion?.carrier || {
    exclusion: {},
    jobLabel: '',
    loaded: false,
  },
  loading: persistedStore?.exclusion?.loading || false,
});

export class ExclusionState extends record implements IExclusionState {}

// reducer
export default function reducer(state = new ExclusionState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetExclusions: {
      return state.set(action.payload.type, {
        exclusion: action.payload.exclusions,
        loaded: true,
        jobLabel: action.payload.jobLabel,
      });
    }

    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    default: {
      return state;
    }
  }
}

export const setExclusions = (type: ExclusionType, jobLabel: string, exclusions: ExclusionMap): ISetExclusions => ({
  type: ActionTypes.SetExclusions,
  payload: { type, exclusions, jobLabel },
});

// selectors
export const getBucketExclusions = (rootState: RootState): ExclusionCollection =>
  rootState.getIn(['exclusion', ExclusionType.BUCKET]) as ExclusionCollection;

export const getCarrierExclusions = (rootState: RootState): ExclusionCollection =>
  rootState.getIn(['exclusion', ExclusionType.CARRIER]) as ExclusionCollection;
