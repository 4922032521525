export enum Coverages {
  GENERAL_LIABILITY = 'General Liability',
  WORKERS_COMP = 'Workers Compensation',
  COMMERCIAL_AUTO = 'Commercial Auto',
  BOP = "Business Owner's Policy",
  PROFESSIONAL_LIABILITY = 'Professional Liability',
  CYBER_LIABILITY = 'Cyber Liability',
}

export type Coverage =
  | 'GENERAL_LIABILITY'
  | 'WORKERS_COMP'
  | 'COMMERCIAL_AUTO'
  | 'BOP'
  | 'PROFESSIONAL_LIABILITY'
  | 'CYBER_LIABILITY';

export enum BusinessStructure {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  PARTNERSHIP = 'PARTNERSHIP',
  LLC = 'LLC',
  S_CORPORATION = 'S_CORPORATION',
  C_CORPORATION = 'C_CORPORATION',
  JOINT_VENTURE = 'JOINT_VENTURE',
  TRUST = 'TRUST',
  ASSOCIATION = 'ASSOCIATION',
  MUNICIPALITY = 'MUNICIPALITY',
  OTHER = 'OTHER',
}

export const BUSINESS_STRUCTURE = [
  {
    id: BusinessStructure.SOLE_PROPRIETORSHIP,
    label: 'Sole Proprietorship',
  },
  {
    id: BusinessStructure.PARTNERSHIP,
    label: 'Partnership',
  },
  {
    id: BusinessStructure.LLC,
    label: 'LLC',
  },
  {
    id: BusinessStructure.S_CORPORATION,
    label: 'S Corporation',
  },
  {
    id: BusinessStructure.C_CORPORATION,
    label: 'C Corporation',
  },
  {
    id: BusinessStructure.JOINT_VENTURE,
    label: 'Joint Venture',
  },
  {
    id: BusinessStructure.TRUST,
    label: 'Trust',
  },
  {
    id: BusinessStructure.ASSOCIATION,
    label: 'Association',
  },
  {
    id: BusinessStructure.MUNICIPALITY,
    label: 'Municipality',
  },
  {
    id: BusinessStructure.OTHER,
    label: 'Other',
  },
];

export enum InsuranceStatus {
  I_CURRENTLY_HAVE_INSURANCE = 'I_CURRENTLY_HAVE_INSURANCE',
  I_DONT_CURRENTLY_HAVE_INSURANCE = 'I_DONT_CURRENTLY_HAVE_INSURANCE',
}

export const INSURANCE_STATUS = [
  {
    id: InsuranceStatus.I_CURRENTLY_HAVE_INSURANCE,
    label: 'I Currently Have Insurance',
  },
  {
    id: InsuranceStatus.I_DONT_CURRENTLY_HAVE_INSURANCE,
    label: "I Don't Currently Have Insurance",
  },
];

export enum BusinessOperations {
  YEAR_ROUND = 'YEAR_ROUND',
  SEASONAL = 'SEASONAL',
}

export const BUSINESS_OPERATIONS = [
  {
    id: BusinessOperations.YEAR_ROUND,
    label: 'Year round',
  },
  {
    id: BusinessOperations.SEASONAL,
    label: 'Seasonal',
  },
];

export enum BusinessEmployees {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  FULL_TIME_AND_PART_TIME = 'FULL_TIME_AND_PART_TIME',
  NONE = 'NONE',
}

export const BUSINESS_EMPLOYEES = [
  {
    id: BusinessEmployees.FULL_TIME,
    label: 'I have full time employees',
  },
  {
    id: BusinessEmployees.PART_TIME,
    label: 'I have part time employees',
  },
  {
    id: BusinessEmployees.FULL_TIME_AND_PART_TIME,
    label: 'I have both full time and part time employees',
  },
  {
    id: BusinessEmployees.NONE,
    label: 'No, I do not have either',
  },
];

export enum CoverageLength {
  I_WANT_COVERAGE_FOR_THE_YEAR = 'I_WANT_COVERAGE_FOR_THE_YEAR',
  I_WANT_SHORT_TERM_COVERAGE = 'I_WANT_SHORT_TERM_COVERAGE',
}

export const COVERAGE_LENGTH = [
  {
    id: CoverageLength.I_WANT_COVERAGE_FOR_THE_YEAR,
    label: 'I Want Coverage For The Year',
  },
  {
    id: CoverageLength.I_WANT_SHORT_TERM_COVERAGE,
    label: 'I Want Short Term Coverage',
  },
];

export enum BuildingRelationship {
  MY_HOME = 'My home',
  APARTMENT = 'An apartment',
  BUILDING_LEASE = 'A building I lease',
  BUILDING_OWNED = 'A building I own and run my business out of',
  BUILDING_OWNED_LEASED = 'A building I own/manage and lease to others',
  CONDO_OWNED = 'A commercial condo I own',
  CONDO_LEASED = 'A commercial condo I lease',
  KIOSK = 'A kiosk',
}

export enum FireProtectionSystem {
  CENTRAL = 'Central Station Fire Protection Service, Fire Alarms, or Smoke Detectors (3rd party, constantly monitored)',
  FIRE_DEPT = 'Fire Department Monitored Fire Protection Service, Fire Alarms, or Smoke Detectors',
  LOCAL = 'Local Fire Alarms or Smoke Detectors (on premises only)',
  NONE = 'None of the above',
}

export enum SecuritySystem {
  CENTRAL = 'Central Station Security System or Burglar Alarms (3rd party, constantly monitored)',
  FIRE_DEPT = 'Police Monitored Security System or Burglar Alarms',
  LOCAL = 'Local Security System, Burglar Alarms or Full Perimeter Intrusion Alarm (on premises only)',
  NONE = 'None of the above',
}

export enum BuildingConstruction {
  FRAME = 'Frame',
  JM = 'Joisted Masonry',
  MNC = 'Masonry Non Combustible',
  NC = 'Non Combustible',
  FR = 'Fire Resistive',
  MFR = 'Modified Fire Resistive',
}

export type NumberOrString = number | string;

export type Job = {
  label: string;
  id: string | number;
  activityID?: string;
  popular?: boolean;
};

export enum InfoType {
  VIN = 'VIN',
  MANUAL = 'MANUAL',
}
