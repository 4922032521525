import { Intercom } from './utils/intercom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { init } from '@boldpenguin/sdk';
import { BpEnvironmentName } from '@boldpenguin/sdk';
import theme from './utils/theme';
import store from './redux/store';
import App from './App';
import './index.css';
import * as FullStory from '@fullstory/browser';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

FullStory.init(
  {
    orgId: 'o-1CZ4ZR-na1',
    recordCrossDomainIFrames: true,
    devMode: process.env.REACT_APP_BP_ENV === 'beta',
  },
  ({ sessionUrl }) => console.log(`Started session: ${sessionUrl}`)
);

init({
  clientId: process.env.REACT_APP_BP_CLIENT_ID as string,
  env: process.env.REACT_APP_BP_ENV as BpEnvironmentName,
  googleApiToken: process.env.REACT_APP_GOOGLE_API_TOKEN as string,
  wsBaseUri: process.env.REACT_APP_BP_WS_BASE_URI as string,
  authUri: process.env.REACT_APP_BP_AUTH_URI as string,
  uri: process.env.REACT_APP_BP_URI as string,
  debug: process.env.REACT_APP_BP_WS_BASE_DEBUG === '1',
}).then(() => {
  import('@boldpenguin/sdk').then(() => {
    root.render(
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Intercom>
              <App />
            </Intercom>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    );
  });
});

reportWebVitals();
serviceWorkerRegistration.register();
