import { AddressElement } from '@stripe/react-stripe-js';
import { Record } from 'immutable';
import { getStore } from '../utils/persist';
import { RootState } from './types';

// types
interface IBusinessDetailFormData {
  name: string;
  address: string;
  state: string;
  city: string;
  zipCode: string;
  owners: string;
}

interface IBusinessDetailsSearchStatus {
  found?: boolean;
}

export interface IBusinessDetailsState extends IBusinessDetailFormData, IBusinessDetailsSearchStatus {}

enum ActionTypes {
  SetDetails = 'BusinessDetails/SetDetails',
  SetSearchStatus = 'BusinessDetails/SetSearchStatus',
  SetBusinessName = 'BusinessDetails/SetBusinessName',
  SetBusinessOwners = 'BusinessDetails/SetBusinessOwners',
  SetBusinessAddress = 'BusinessDetails/SetBusinessAddress',
  SetCity = 'BusinessDetails/SetCity',
  SetState = 'BusinessDetails/SetState',
  SetZipCode = 'BusinessDetails/SetZipCode',
}

interface ISetBusinessAddress {
  type: ActionTypes.SetBusinessAddress;
  payload: string;
}
interface ISetCity {
  type: ActionTypes.SetCity;
  payload: string;
}

interface ISetState {
  type: ActionTypes.SetState;
  payload: string;
}
interface ISetZip {
  type: ActionTypes.SetZipCode;
  payload: string;
}
interface ISetBusinessOwners {
  type: ActionTypes.SetBusinessOwners;
  payload: string;
}
interface ISetBusinessName {
  type: ActionTypes.SetBusinessName;
  payload: string;
}

interface ISetDetails {
  type: ActionTypes.SetDetails;
  payload: {
    details: IBusinessDetailFormData;
  };
}

interface ISetSearchStatus {
  type: ActionTypes.SetSearchStatus;
  payload: {
    found: boolean;
  };
}

type Actions =
  | ISetDetails
  | ISetSearchStatus
  | ISetBusinessOwners
  | ISetBusinessName
  | ISetCity
  | ISetState
  | ISetZip
  | ISetBusinessAddress;

// state
const persistedStore = getStore();

const record = Record<IBusinessDetailsState>(
  persistedStore?.businessDetails
    ? persistedStore.businessDetails
    : {
        name: '',
        address: '',
        state: '',
        zipCode: '',
        city: '',
        owners: '',
        found: false,
      }
);

class BusinessDetailsState extends record implements IBusinessDetailsState {
  setDetails(action: ISetDetails) {
    const { name, address, zipCode, state, city, owners } = action.payload.details;
    return this.set('name', name)
      .set('address', address)
      .set('zipCode', zipCode)
      .set('state', state)
      .set('city', city)
      .set('owners', owners);
  }
}

// reducer
export default function reducer(state = new BusinessDetailsState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetDetails: {
      return state.setDetails(action);
    }

    case ActionTypes.SetSearchStatus: {
      return state.set('found', action.payload.found);
    }

    case ActionTypes.SetBusinessOwners: {
      return state.set('owners', action.payload);
    }

    case ActionTypes.SetBusinessName: {
      return state.set('name', action.payload);
    }

    case ActionTypes.SetCity: {
      return state.set('city', action.payload);
    }

    case ActionTypes.SetState: {
      return state.set('state', action.payload);
    }

    case ActionTypes.SetZipCode: {
      return state.set('zipCode', action.payload);
    }

    case ActionTypes.SetBusinessAddress: {
      return state.set('address', action.payload);
    }

    default: {
      return state;
    }
  }
}

// actions

export const setBusinessOwners = (owners: string): ISetBusinessOwners => ({
  type: ActionTypes.SetBusinessOwners,
  payload: owners,
});

export const setBusinessName = (name: string): ISetBusinessName => ({
  type: ActionTypes.SetBusinessName,
  payload: name,
});

export const setBusinessAddress = (address: string): ISetBusinessAddress => ({
  type: ActionTypes.SetBusinessAddress,
  payload: address,
});

export const setCity = (city: string): ISetCity => ({
  type: ActionTypes.SetCity,
  payload: city,
});

export const setState = (state: string): ISetState => ({
  type: ActionTypes.SetState,
  payload: state,
});

export const setZipCode = (zipCode: string): ISetZip => ({
  type: ActionTypes.SetZipCode,
  payload: zipCode,
});

export const setBusinessDetails = (details: IBusinessDetailFormData): ISetDetails => ({
  type: ActionTypes.SetDetails,
  payload: {
    details,
  },
});

export const setBusinessDetailsStatus = (found: boolean): ISetSearchStatus => ({
  type: ActionTypes.SetSearchStatus,
  payload: {
    found,
  },
});

// selectors
export const getBusinessDetails = (rooState: RootState): IBusinessDetailsState =>
  rooState.get('businessDetails').toJS() as IBusinessDetailsState;

export const getBusinessDetailsSearchStatus = (rooState: RootState): boolean =>
  rooState.getIn(['businessDetails', 'found']) as boolean;
