import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
});

if (sessionStorage.getItem('auth_token') !== null) {
  instance.defaults.headers.common['Authorization'] = sessionStorage.getItem('auth_token') as string;
}

export function setAuthToken(token: string) {
  instance.defaults.headers.common['Authorization'] = token;
  sessionStorage.setItem('auth_token', token);
}

export const fetchUser = async (token: string) => {
  try {
    const { data } = await instance({
      method: 'get',
      url: '/users',
      headers: { Authorization: token },
    });
    return data;
  } catch (e: any) {
    return Promise.resolve(getError(e));
  }
};

export const fetchHomeDetails = async () => {
  try {
    const { data } = await instance({
      method: 'get',
      url: '/users/home',
    });

    const response = data;

    return {
      ...response,
      JobTypes: {
        ...response.JobTypes,
        OTHER: 'other',
        I_DONT_SEE_MY_PROFESSION: 'I don’t see my profession',
      },
    };
  } catch (e) {}
};

export const createForm = async () => {
  return instance({
    method: 'get',
    url: `/bp`,
  }).then(res => res.data);
};

export const getConsumerForm = async () => {
  return instance({
    method: 'get',
    url: `/bp`,
  }).then(res => res.data);
};

export const addUserAPI = async (params: any) => {
  try {
    const { data } = await instance({ method: 'post', url: '/users', data: params });
    return data;
  } catch (e: any) {
    return Promise.resolve(getError(e));
  }
};

export const updateUser = async (userId: string, params: any) => {
  try {
    const { data } = await instance({ method: 'put', url: `/users/${userId}`, data: params });
    return data;
  } catch (e: any) {
    return Promise.resolve(getError(e));
  }
};

//Thimble Flow

interface IFetchActivitesParams {
  zipcode: string;
  state: string;
  activityId: string;
  numOfEmployeesForAdditionalCoverage: number;
  includeAdditionalCoverage: boolean;
  numOfPartTimeOrW2Workers: number;
  jobDays: {
    startDate: string;
    endDate: string;
  };
  isAdditionalCoverageNeeded: boolean;
}

export const fetchActivities = async ({
  zipcode,
  state,
  activityId,
  numOfEmployeesForAdditionalCoverage,
  includeAdditionalCoverage,
  numOfPartTimeOrW2Workers,
  jobDays,
  isAdditionalCoverageNeeded,
}: IFetchActivitesParams) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: '/thimble/activities',
      data: {
        zipcode,
        state,
        activityId,
        includeAdditionalCoverage,
        numOfPartTimeOrW2Workers,
        jobDays,
        isAdditionalCoverageNeeded,
      },
    });

    return data.data;
  } catch (e) {}
};

interface IFetchInsurancePlansParams {
  activityId: string;
  zipcode: string;
  limits: [];
  jobDays: {
    startDate: string;
    endDate: string;
  };
  activities: [];
}

export const fetchCoverageOptions = async ({
  zipcode,
  activityId,
  limits,
  activities,
  jobDays,
}: IFetchInsurancePlansParams) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: '/thimble/insurance-plans',
      data: {
        activityId,
        zipcode,
        limits,
        jobDays,
        activities,
      },
    });

    return data.data;
  } catch (e: any) {
    return Promise.reject(getError(e));
  }
};

interface IAddPlanToCartParams {
  activityId?: string;
  zipcode?: string;
  planId?: string;
  limit?: number;
  activities?: any[];
  jobDays?: {
    startDate: string;
    endDate: string;
  };
  addons?: any[];
  planDuration?: string;
}

export const addPlanToCart = async ({
  zipcode,
  activityId,
  limit,
  activities,
  jobDays,
  planId,
  addons,
  planDuration: planDurantion,
}: IAddPlanToCartParams) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: 'cart/thimble/add-to-cart',
      data: {
        zipcode,
        planDurantion,
        activityId,
        planId,
        limit,
        addons,
        activities,
        jobDays,
      },
    });

    return data.data;
  } catch (e) {}
};

interface IAddPlanToCartAnnualParams {
  paymentOption: string;
  activityId: string;
  zipcode: string;
  startDate: string;
}

export const addPlanToCartAnnual = async (payload: IAddPlanToCartAnnualParams) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: 'cart/thimble/annual/add-to-cart',
      data: payload,
    });

    return data.data;
  } catch (e) {}
};

export const getPaymentIntent = async (cartId: string) => {
  try {
    const { data } = await instance({
      method: 'post',
      url: '/thimble/payment-intent',
      data: {
        cartId,
      },
    });
    return data.data;
  } catch (e: any) {
    return Promise.reject(getError(e));
  }
};

export const confirmPayment = async ({ cartId, stripeToken }: { cartId: string; stripeToken: string }) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: '/thimble/stripe-payment',
      data: {
        stripeToken: stripeToken,
        cartId: cartId,
      },
    });
    return data.data;
  } catch (e) {}
};

export const getTier3 = async (zipCode: string, ip: string) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: 'https://www.nextinsure.com/listingdisplay/listings',
      data: {
        tracking: {
          ip,
          ua: navigator.userAgent,
          ni_ad_client: '690394',
          ni_zc: zipCode,
          ni_ref: 'https://web.insurepro.com/carrier-quotes',
        },
      },
    });

    return data.response.listingset.listing;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCarrierDetails = async (carrierCode: string, formId: string) => {
  try {
    const { data } = await instance({
      method: 'post',
      url: '/bp/quote/info',
      data: {
        carrierCode,
        bpReferenceId: formId,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export interface ISendCarrierDetailsParams {
  bpReferenceId: string;
  conversationId: string;
  carrier: string;
  productType: string;
  annualPrice: number;
  monthlyPrice: number;
  quoteNumber: string;
}

export const sendCarrierDetails = async (data: ISendCarrierDetailsParams) => {
  try {
    await instance({
      method: 'post',
      url: '/intercom',
      data,
    });
  } catch (e) {
    return Promise.reject(e);
  }
};
export interface ICreatePolicyParams {
  quoteId: string;
  cartId: string;
  paymentResponse?: any;
}

export const createPolicy = async ({ quoteId, cartId, paymentResponse }: ICreatePolicyParams) => {
  try {
    const { data } = await instance({
      method: 'POST',
      url: '/thimble/create-policy',
      data: {
        quoteId,
        cartId,
        paymentResponse,
      },
    });
    return data.data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const getDirectRates = async () => {
  try {
    const { data } = await instance({
      method: 'get',
      url: `/carrier-integrations/quote`,
    });
    return data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const jobTypeSearch = async (query: string, keywordThreshold: number = 0.3) => {
  try {
    const { data } = await instance({
      method: 'post',
      url: '/ml/job-type-search',
      data: { query, keywordThreshold },
    });

    const { keyWords, jobType_popular, jobType_rest } = data;
    const jobsPopular = Object.values(jobType_popular).map((x: any) => ({
      id: x.naicsCode,
      label: x.jobTitle,
      activityID: x.thimbleActivityId,
      popular: true,
    }));
    const jobsRest = Object.values(jobType_rest).map((x: any) => ({
      id: x.naicsCode,
      label: x.jobTitle,
      activityID: x.thimbleActivityId,
      popular: false,
    }));

    return { keyWords, jobs: [...jobsPopular, ...jobsRest] };
  } catch (e: any) {
    return Promise.reject(getError(e));
  }
};

export const makePayment = async (payload: any) => {
  try {
    const { data } = await instance({
      method: 'post',
      url: `/carrier-integrations/bind`,
      data: payload,
    });
    return data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export enum AggregateLevel {
  CARRIER = 'CARRIER',
  BUCKET = 'BUCKET',
}

export const getExclusions = async (jobType: string, aggregateLevel: 'CARRIER' | 'BUCKET') => {
  try {
    const response = await instance({
      method: 'post',
      url: '/ml/exclusions',
      data: {
        jobType,
        aggregateLevel,
      },
    });

    return response.data;
  } catch (e: any) {
    return Promise.reject(getError(e));
  }
};

const getError = (e: any): string => {
  if (e?.response?.data?.data.length && e?.response?.data?.data[0]?.message) {
    return e?.response?.data?.data[0]?.message;
  } else {
    return e?.response?.data?.message;
  }
};

export const getFastPassCarriers = async () => {
  try {
    const response = await instance({
      method: 'get',
      url: '/ml/fastpass',
    });
    return response.data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const getCIAppetite = async () => {
  try {
    const response = await instance({
      method: 'get',
      url: '/carrier-integrations/appetite',
    });
    return response.data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const getEmployersPaymentToken = async () => {
  try {
    const response = await instance({
      method: 'get',
      url: '/carrier-integrations/employers/token',
    });
    return response.data;
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const postWindowCloseMessage = async (cancelEvent = false, active = false) => {
  return fetch(`${process.env.REACT_APP_API_URL as string}/epic/push?event=${cancelEvent}&active=${active}`, {
    method: 'POST',
    keepalive: true,
    headers: {
      Authorization: sessionStorage.getItem('auth_token') as string,
    },
  });
};
