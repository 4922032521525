import { Record } from 'immutable';
import { RootState } from './types';
import { getStore } from '../utils/persist';

// types
enum ActionTypes {
  SetLoading = 'PlanBuilder/SetLoading',
  SetCoverageLimit = 'PlanBuilder/SetCoverageLimit',
  SetGrossRevenue = 'PlanBuilder/SetGrossRevenue',
  SetFullTimeEmployees = 'PlanBuilder/SetFullTimeEmployees',
  SetPartTimeEmployees = 'PlanBuilder/SetPartTimeEmployees',
  SetPayrollValue = 'PlanBuilder/SetPayrollValue',
  SetAdditionalInsured = 'PlanBuilder/SetAdditionalInsured',
  SetWaiverOfSubrogation = 'PlanBuilder/SetWaiverOfSubrogation',
  SetPremiumEstimation = 'PlanBuilder/SetPremiumEstimation',
  SetBucketsSelection = 'PlanBuilder/SetBucketsSelection',
  SetKayakQuoteFork = 'PlanBuilder/SetKayakQuoteFork',
}

interface ISetLoading {
  type: ActionTypes.SetLoading;
  payload: {
    loading: boolean;
  };
}

interface ISetPremiumEstimation {
  type: ActionTypes.SetPremiumEstimation;
  payload: {
    premiumEstimation: IPremiumEstimation;
  };
}

interface ISetCoverageLimit {
  type: ActionTypes.SetCoverageLimit;
  payload: {
    coverageLimit: number;
  };
}

interface ISetGrossRevenue {
  type: ActionTypes.SetGrossRevenue;
  payload: {
    grossRevenue: number | null;
  };
}

interface ISetFullTimeEmployees {
  type: ActionTypes.SetFullTimeEmployees;
  payload: {
    fullTimeEmployees: number;
  };
}

interface ISetPartTimeEmployees {
  type: ActionTypes.SetPartTimeEmployees;
  payload: {
    partTimeEmployees: number;
  };
}

interface ISetPayrollValue {
  type: ActionTypes.SetPayrollValue;
  payload: {
    name: 'owner' | 'w2Employees' | 'contractors';
    value: number | null;
  };
}

interface ISetAdditionalInsured {
  type: ActionTypes.SetAdditionalInsured;
  payload: {
    AI: boolean;
  };
}

interface ISetWaiverOfSubrogation {
  type: ActionTypes.SetWaiverOfSubrogation;
  payload: {
    WOS: boolean;
  };
}

interface ISetBucketsSelection {
  type: ActionTypes.SetBucketsSelection;
  payload: {
    bucketsSelection: IBucketsSelection;
  };
}

interface ISetKayakQuoteFork {
  type: ActionTypes.SetKayakQuoteFork;
}

type Actions =
  | ISetLoading
  | ISetCoverageLimit
  | ISetGrossRevenue
  | ISetFullTimeEmployees
  | ISetPartTimeEmployees
  | ISetPayrollValue
  | ISetAdditionalInsured
  | ISetWaiverOfSubrogation
  | ISetPremiumEstimation
  | ISetBucketsSelection
  | ISetKayakQuoteFork;

// state
export interface IPremiumEstimation {
  bigName: number;
  affordable: number;
  flexible: number;
}

export type BucketName = 'bigName' | 'affordable' | 'flexible';

export interface IBucketsSelection {
  affordable: boolean;
  bigName: boolean;
  flexible: boolean;
}

export interface IPlanBuilderState {
  isKayakQuoteFork: boolean;
  premiumEstimation: IPremiumEstimation;
  loading: boolean;
  coverageLimit: number;
  grossRevenue: number | null;
  fullTimeEmployees: number;
  partTimeEmployees: number;
  AI?: boolean;
  WOS?: boolean;
  payroll: {
    owner: number;
    w2Employees: number;
    contractors: number;
  };
  bucketsSelection: IBucketsSelection;
}

const persistedStore = getStore();

const record = Record<IPlanBuilderState>(
  persistedStore?.planBuilder
    ? persistedStore?.planBuilder
    : {
        isKayakQuoteFork: false,
        loading: false,
        coverageLimit: 1000000,
        grossRevenue: 0 as number | null,
        fullTimeEmployees: 0,
        partTimeEmployees: 0,
        payroll: {
          w2Employees: 0,
          owner: 0,
          contractors: 0,
        },
        AI: undefined as boolean | undefined,
        WOS: undefined as boolean | undefined,
        premiumEstimation: {
          affordable: 0,
          bigName: 0,
          flexible: 0,
        },
        bucketsSelection: {
          bigName: false,
          affordable: false,
          flexible: false,
        },
      }
);

class PlanBuilderState extends record implements IPlanBuilderState {}

// reducer
export default function reducer(state = new PlanBuilderState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetLoading: {
      return state.set('loading', action.payload.loading);
    }

    case ActionTypes.SetCoverageLimit: {
      return state.set('coverageLimit', action.payload.coverageLimit);
    }

    case ActionTypes.SetGrossRevenue: {
      return state.set('grossRevenue', action.payload.grossRevenue);
    }

    case ActionTypes.SetFullTimeEmployees: {
      return state.set('fullTimeEmployees', action.payload.fullTimeEmployees);
    }

    case ActionTypes.SetPartTimeEmployees: {
      return state.set('partTimeEmployees', action.payload.partTimeEmployees);
    }

    case ActionTypes.SetPayrollValue: {
      return state.setIn(['payroll', action.payload.name], action.payload.value);
    }

    case ActionTypes.SetAdditionalInsured: {
      return state.set('AI', action.payload.AI);
    }

    case ActionTypes.SetWaiverOfSubrogation: {
      return state.set('WOS', action.payload.WOS);
    }

    case ActionTypes.SetPremiumEstimation: {
      return state.set('premiumEstimation', action.payload.premiumEstimation);
    }

    case ActionTypes.SetBucketsSelection: {
      return state.set('bucketsSelection', action.payload.bucketsSelection);
    }

    case ActionTypes.SetKayakQuoteFork: {
      return state.set('isKayakQuoteFork', true);
    }

    default: {
      return state;
    }
  }
}

// actions
export const setLoading = (loading: boolean): ISetLoading => ({
  type: ActionTypes.SetLoading,
  payload: {
    loading,
  },
});

export const setCoverageLimit = (coverageLimit: number): ISetCoverageLimit => ({
  type: ActionTypes.SetCoverageLimit,
  payload: {
    coverageLimit,
  },
});

export const setGrossRevenue = (grossRevenue: number | null): ISetGrossRevenue => ({
  type: ActionTypes.SetGrossRevenue,
  payload: {
    grossRevenue,
  },
});

export const setFullTimeEmployees = (fullTimeEmployees: number): ISetFullTimeEmployees => ({
  type: ActionTypes.SetFullTimeEmployees,
  payload: {
    fullTimeEmployees,
  },
});

export const setPartTimeEmployees = (partTimeEmployees: number): ISetPartTimeEmployees => ({
  type: ActionTypes.SetPartTimeEmployees,
  payload: {
    partTimeEmployees,
  },
});

export const setPayrollValue = (
  name: 'owner' | 'w2Employees' | 'contractors',
  value: number | null
): ISetPayrollValue => ({
  type: ActionTypes.SetPayrollValue,
  payload: {
    name,
    value,
  },
});

export const setAdditionalInsured = (AI: boolean): ISetAdditionalInsured => ({
  type: ActionTypes.SetAdditionalInsured,
  payload: {
    AI,
  },
});

export const setWaiverOfSubrogation = (WOS: boolean): ISetWaiverOfSubrogation => ({
  type: ActionTypes.SetWaiverOfSubrogation,
  payload: {
    WOS,
  },
});

export const setPremiumEstimation = (premiumEstimation: IPremiumEstimation): ISetPremiumEstimation => ({
  type: ActionTypes.SetPremiumEstimation,
  payload: {
    premiumEstimation,
  },
});

export const setBucketsSelection = (bucketsSelection: IBucketsSelection): ISetBucketsSelection => ({
  type: ActionTypes.SetBucketsSelection,
  payload: {
    bucketsSelection,
  },
});

export const setKayakQuoteFork = (): ISetKayakQuoteFork => ({
  type: ActionTypes.SetKayakQuoteFork,
});

// selectors
export const getPlanBuilder = (rooState: RootState): IPlanBuilderState => rooState.get('planBuilder').toJS();

export const getBucketsSelection = (rooState: RootState): IBucketsSelection =>
  rooState.getIn(['planBuilder', 'bucketsSelection']) as IBucketsSelection;

export const getPremiumEstimation = (rooState: RootState): IPremiumEstimation =>
  rooState.getIn(['planBuilder', 'premiumEstimation']) as IPremiumEstimation;
