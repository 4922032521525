import { Record } from 'immutable';
import { ActionTypes } from './types';
import {
  ICoterieState,
  ISetPaymentOption,
  ISetPurchasedPolicyDetails,
  ISetSelectedQuote,
  PaymentOptions,
} from './interface';
import { getStore } from '../../utils/persist';

type Actions = ISetSelectedQuote | ISetPaymentOption | ISetPurchasedPolicyDetails;

const persistedStore = getStore();

const record = Record<ICoterieState>({
  selectedQuote: persistedStore?.coterie?.selectedQuote || null,
  paymentOption: persistedStore?.coterie?.paymentOption || PaymentOptions.Monthly,
  purchasedPolicyDetails: persistedStore?.coterie?.purchasedPolicyDetails || null,
});

class CoterieState extends record implements ICoterieState {}

export default function reducer(state = new CoterieState(), action: Actions) {
  switch (action.type) {
    case ActionTypes.SetSelectedQuote: {
      return state.set('selectedQuote', action.payload.selectedQuote);
    }

    case ActionTypes.SetPurchasedPolicyDetails: {
      return state.set('purchasedPolicyDetails', action.payload.purchasedPolicyDetails);
    }

    case ActionTypes.SetPaymentOption: {
      return state.set('paymentOption', action.payload.paymentOption);
    }

    default: {
      return state;
    }
  }
}
