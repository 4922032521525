import { combineReducers } from 'redux-immutable';
import { Reducer } from 'redux';
import global from './global';
import prefill from './prefill';
import planBuilder from './plan-builder';
import modeler from './modeler';
import businessDetails from './business-details';
import source from './source';
import user from './user';
import thimble from './thimble';
import thimbleFlow from './thimbleFlow/reducer';
import intercom from './intercom';
import bp from './bp';
import coterie from './coterie/reducer';
import jobSearch from './job-search';
import exclusion from './exclusion';
import vehicle from './vehicle';
import driver from './driver';

const rootReducer: Reducer = combineReducers({
  global,
  prefill,
  thimble,
  thimbleFlow,
  planBuilder,
  modeler,
  businessDetails,
  source,
  user,
  intercom,
  bp,
  coterie,
  jobSearch,
  exclusion,
  vehicle,
  driver,
});

export default rootReducer;
